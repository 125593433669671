import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PatientService } from '../../services/patient.service';
import { ClinicService } from '../../services/clinic.service';
import { Patient } from '../model/patient';
import { Clinic } from '../model/clinic';

@Component({
  selector: 'app-patient-short-modal',
  templateUrl: './patient-short-modal.component.html',
  styleUrls: ['./patient-short-modal.component.scss']
})
export class PatientShortModalComponent implements OnInit{

  // Input fields
  lastName: String = "";
  firstName: String = "";
  phone: String = "";
  email: String = "";
  cnp: String = "";
  clinic: Clinic | null = null;
  observations: String = "";

  clinics: Clinic[] = [];

  constructor(public dialogRef: MatDialogRef<PatientShortModalComponent>,
    private patientService: PatientService,
    private clinicService: ClinicService) {

  }

  ngOnInit(): void {
    //TODO: when implementing clinics -> retrieve them
    this.getClinics();
  }

  onObservationsInput(event: Event) {
    this.observations = (event.target as HTMLTextAreaElement).value;
  }

  getClinics(): void {
    this.clinicService.getClinics().subscribe(clinics => {
      this.clinics = clinics;
    })
  }

  addPatient(): void {
    const patient: Patient = {
      lastName: this.lastName,
      firstName: this.firstName,
      cnp: this.cnp,
      phone: this.phone,
      email: this.email,
      birthdate: null,
      gender: "-",
      country: "-",
      county: "-",
      city: "-",
      street: "-",
      ownerLastName: "-",
      ownerFirstName: "-",
      ownerPhone: "-",
      familyName: "-",
      familyMember: "-",
      language: "-",
      secondaryContact: "-",
      smsNotifications: false,
      emailNotifications: false,
      smsMarketing: false,
      emailMarketing: false,
      phoneMarketing: false,
      clinic: this.clinic,
      doctor: null,
      observations: this.observations
    }

    this.patientService.addPatient(patient).subscribe(response => {
      // this.toastr.success('Medic added successfully');
    }, error => {
      // this.toastr.error('Failed to add medic');
    })
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }

}
