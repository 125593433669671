export class SpeechRecognitionService {
    recognition: any;

    constructor() {
    // Check if webkitSpeechRecognition is available, otherwise, fall back to SpeechRecognition
        if ('webkitSpeechRecognition' in window) {
            this.recognition = window['webkitSpeechRecognition'];
        }
        if (!this.recognition) {
            console.error("Speech recognition not supported.");
        } else {
            this.recognition = new this.recognition();
            this.recognition.lang = 'ro';
            this.recognition.interimResults = false;
        }
    }

    startRecognition(): Promise<string> {
        return new Promise((resolve, reject) => {
            this.recognition.start();
            this.recognition.onresult = (event: any) => {
                const result = event.results[0][0].transcript;
                resolve(result);
            };
            this.recognition.onerror = (event: any) => {
                reject(event.error);
            };
        });
    }

    stopRecognition() {
        this.recognition.stop();
    }
}