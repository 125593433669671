import { Component, Input, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { TreatmentCategoryService } from '../../services/treatment.category.service';
import { TreatmentCategory } from '../model/treatmentCategory';
import { ToastrService } from 'ngx-toastr'; 

@Component({
  selector: 'app-treatment-category-modal',
  templateUrl: './treatment-category-modal.component.html',
  styleUrls: ['./treatment-category-modal.component.scss']
})
export class TreatmentCategoryModalComponent implements OnInit{

  @Input() data: {
    treatmentCategoryToUpdate: TreatmentCategory,
    isEdit: boolean
  }

  // title
  formTitle: String = "ADAUGA CATEGORIE TRATAMENT";
  formTitleIcon: String = "addthis";

  // selected items
  currentTreatmentCategory: TreatmentCategory;

  constructor(
    @Inject(DOCUMENT) document: Document,
    public dialogRef: MatDialogRef<string>,
    private treatmentCategoryService: TreatmentCategoryService,
    private toastr: ToastrService) {
      this.data = {
        treatmentCategoryToUpdate: {
          categoryName: ""
        },
        isEdit: false
      }
      this.currentTreatmentCategory = this.data.treatmentCategoryToUpdate;
    }

  ngOnInit(): void {
    this.currentTreatmentCategory = this.data.treatmentCategoryToUpdate;
    if (this.data.isEdit) {
      document.getElementById('form-title')?.classList.add('form-title-edit');
      this.formTitle = "EDITEAZA CATEGORIE TRATAMENT";
      this.formTitleIcon = "pencil";
    }
  }

  // CRUD operations
  addTreatmentCategory(): void {
    if (this.isValidCategory()) {
      this.treatmentCategoryService.addTreatmentCategory(this.currentTreatmentCategory).subscribe(response => {
        // this.toastr.success('Medic added successfully');
      }, error => {
        // this.toastr.error('Failed to add medic');
      })
      this.dialogRef.close();
    }
  }

  updateTreatmentCategory(): void {
    if (this.isValidCategory()) {
      this.treatmentCategoryService.updateTreatmentCategory(this.currentTreatmentCategory).subscribe(response => {
        // this.toastr.success('Medic updated successfully');
      }, error => {
        // this.toastr.error('Failed to update medic');
      })
      this.dialogRef.close();
    }
  }

  isValidCategory(): boolean {
    if (this.currentTreatmentCategory.categoryName === null || 
      this.currentTreatmentCategory.categoryName === undefined) {
        document.getElementById('categoryName')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('categoryName')?.classList.remove('invalid-field');
    return true;
  }

  save(): void {
    if (this.data.isEdit) {
      this.updateTreatmentCategory();
    } else {
      this.addTreatmentCategory();
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
