import { LOCALE_ID, Component, Inject, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import {
  DatePipe, 
  FormStyle, 
  getLocaleDayNames, 
  TranslationWidth } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { CustomDate } from '../model/custom_date';
import { Appointment } from '../model/appointment';
import { Medic } from '../model/medic';
import { Clinic } from '../model/clinic';
import { AppointmentModalComponent } from '../appointment-modal/appointment-modal.component';
import { AppointmentService } from '../../services/appointment.service';
import { MedicService } from '../../services/medic.service';
import { ClinicService } from '../../services/clinic.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})

export class AppointmentComponent implements OnInit, OnChanges{

  selectedDoctor: Medic | null = null;
  selectedClinic: Clinic | null = null;
  
  showDoctorCalendar: boolean = true;
  showClinicCalendar: boolean = false;

  dayRange1: boolean = false;
  dayRange3: boolean = false;
  dayRange5: boolean = false;
  dayRange7: boolean = true;

  // calendar variables
  currentDate: Date = new Date();
  leftDateRange: Date = this.currentDate;
  rightDateRange: Date = this.currentDate;
  hoursTimeline:number[] = [];
  weekDaysNames: string[] = [];
  week:CustomDate[] = [];

  // appointments variables
  appointments: Appointment[] = [];
  filteredAppointments: Appointment[] = [];

  // medics/clinics variables
  medics: Medic[] = [];
  clinics: Clinic[] = [];

  cells:number[] = [1, 2, 3, 4];

  // constructor
  constructor(@Inject(LOCALE_ID) public locale: string, 
    private datePipe: DatePipe, public dialog: MatDialog,
    private medicService: MedicService, 
    private appointmentService: AppointmentService,
    private clinicService: ClinicService,
    private toastr: ToastrService) {
      this.getCurrentWeek7Days();
      this.hoursTimeline = [
        8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21
      ]

      const tmpDate = new Date();
      tmpDate.setHours(12, 0, 0, 0);
  }

  ngOnInit(): void {
    this.getAllAppointments();
    this.getAllMedics();
    this.getAllClinics();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getAllAppointments();
  }

  // Toogle functions

  toogleMedicView(): void {
    this.showDoctorCalendar = true;
    this.showClinicCalendar = false;
  }

  toogleClinicView(): void {
    this.showDoctorCalendar = false;
    this.showClinicCalendar = true;
  }

  toogleDayRange1View(): void {
    this.dayRange1 = true;
    this.dayRange3 = false;
    this.dayRange5 = false;
    this.dayRange7 = false;

    this.getCurrentWeekDay();
  }

  toogleDayRange3View(): void {
    this.dayRange1 = false;
    this.dayRange3 = true;
    this.dayRange5 = false;
    this.dayRange7 = false;

    this.getCurrentWeek3Days();
  }

  toogleDayRange5View(): void {
    this.dayRange1 = false;
    this.dayRange3 = false;
    this.dayRange5 = true;
    this.dayRange7 = false;

    this.getCurrentWeek5Days();
  }

  toogleDayRange7View(): void {
    this.dayRange1 = false;
    this.dayRange3 = false;
    this.dayRange5 = false;
    this.dayRange7 = true;

    this.getCurrentWeek7Days();
  }

// DatePicker/Date functions

  getCurrentDate(): void {
    this.currentDate = new Date();
    this.triggerDateChanged();
  }

  triggerDateChanged(): void {
    if (this.dayRange1) {
      this.getCurrentWeekDay();
    } else if (this.dayRange3) {
      this.getCurrentWeek3Days();
    } else if (this.dayRange5) {
      this.getCurrentWeek5Days();
    } else if (this.dayRange7) {
      this.getCurrentWeek7Days();
    }
  }

  addDay(): void {
    const tmpDate = new Date(this.currentDate);
    if (this.dayRange1) {
      tmpDate.setUTCDate(this.currentDate.getUTCDate() + 1);
    } else if (this.dayRange3) {
      tmpDate.setUTCDate(this.currentDate.getUTCDate() + 3);
    } else if (this.dayRange5) {
      tmpDate.setUTCDate(this.currentDate.getUTCDate() + 5);
    } else if (this.dayRange7) {
      tmpDate.setUTCDate(this.currentDate.getUTCDate() + 7);
    }
    this.currentDate = tmpDate;
    this.triggerDateChanged();
  }

  removeDay(): void {
    const tmpDate = new Date(this.currentDate);
    if (this.dayRange1) {
      tmpDate.setUTCDate(this.currentDate.getUTCDate() - 1);
    } else if (this.dayRange3) {
      tmpDate.setUTCDate(this.currentDate.getUTCDate() - 3);
    } else if (this.dayRange5) {
      tmpDate.setUTCDate(this.currentDate.getUTCDate() - 5);
    } else if (this.dayRange7) {
      tmpDate.setUTCDate(this.currentDate.getUTCDate() - 7);
    }
    this.currentDate = tmpDate;
    this.triggerDateChanged();
  }

  getCurrentWeekDay() {
    this.weekDaysNames = [];
    this.weekDaysNames.push(this.datePipe.transform(this.currentDate, 'EEEE') as string);
    this.week = [];
    this.week.push(new CustomDate(this.weekDaysNames[0], this.currentDate));
    this.rightDateRange = this.currentDate;
    this.leftDateRange = this.currentDate;
  }

  getCurrentWeek3Days() {
    this.week = [];
    this.weekDaysNames = [];
    this.weekDaysNames = getLocaleDayNames(
      this.locale,
      FormStyle.Standalone,
      TranslationWidth.Abbreviated
    ) as string[];

    const firstDay = new Date(this.currentDate);
    firstDay.setDate(this.currentDate.getDate() - 1);
    this.leftDateRange = firstDay;
    
    this.week.push(new CustomDate(this.weekDaysNames[firstDay.getDay()], firstDay));
    this.week.push(new CustomDate(this.weekDaysNames[this.currentDate.getDay()], this.currentDate));
    
    const day = new Date(firstDay);
    day.setDate(this.currentDate.getDate() + 1);
    this.week.push(new CustomDate(this.weekDaysNames[day.getDay()], day));
    
    this.rightDateRange = day;

  }

  getCurrentWeek5Days() {
    this.week = [];
    this.weekDaysNames = [];

    this.weekDaysNames = getLocaleDayNames(
      this.locale,
      FormStyle.Standalone,
      TranslationWidth.Abbreviated
    ) as string[];

    const currentDay = this.currentDate.getDay();

    // Calculate the first day of the week (Sunday) and adjust for the current day
    const firstDay = new Date(this.currentDate);
    firstDay.setDate(this.currentDate.getDate() - currentDay);

    // Populate the array with dates for the current week
    for (let i = 1; i < 6; i++) {
      const day = new Date(firstDay);
      day.setDate(firstDay.getDate() + i);
      this.week.push(new CustomDate(this.weekDaysNames[i], day));
      // set lastDate to display in range
      if (i === 1) {
        this.leftDateRange = day;
      }
      this.rightDateRange = day;
    }
  }

  getCurrentWeek7Days() {
    this.week = [];
    this.weekDaysNames = getLocaleDayNames(
      this.locale,
      FormStyle.Standalone,
      TranslationWidth.Abbreviated
    ) as string[];

    const currentDay = this.currentDate.getDay();

    // Calculate the first day of the week (Sunday) and adjust for the current day
    const firstDay = new Date(this.currentDate);
    firstDay.setDate(this.currentDate.getDate() - currentDay);
    this.leftDateRange = firstDay;

    // Populate the array with dates for the current week
    for (let i = 0; i < 7; i++) {
      const day = new Date(firstDay);
      day.setDate(firstDay.getDate() + i);
      this.week.push(new CustomDate(this.weekDaysNames[i], day));
      // set lastDate to display in range
      this.rightDateRange = day;
    }
  }

  // Appointments functions

  filterAppointments(day:CustomDate, hour:number):number {
    // const apps: Appointment[] = [];
    this.filteredAppointments = [];
    for(const app of this.appointments) {
      const stringDate: string|undefined = app.date?.toString();
      if (stringDate != undefined) {
        const date = new Date(stringDate);
        const appTime: string[] = app.hour.split(":");
        const appHour = parseInt(appTime[0]);
        if (date.getDate() === day.weekDayNo.getDate()
          && appHour === hour 
          && date.getMonth() === day.weekDayNo.getMonth()
          && date.getFullYear() === day.weekDayNo.getFullYear()) {
            this.filteredAppointments.push(app);
        }
      }
    }
    return this.filteredAppointments.length;
  }

  addAppointment(day:CustomDate, hour: number):void {
    const dialogRef = this.dialog.open(AppointmentModalComponent);
    dialogRef.componentInstance.data = {
      appointmentToUpdate: {
        doctor: null,
        clinic: null,
        patient: null,
        date: day.weekDayNo,
        hour: hour.toString().concat(":00"),
        duration: "60 min",
        information: "",
        blocked: false,
        observations: "",
        confirmed: false,
        arrived: false,
        finalized: false
      },
      isEdit: false
    }

    dialogRef.afterClosed().subscribe(result => {
      this.getAllAppointments();
    });
  }

  editAppointment(appointment: Appointment) {
    const dialogRef = this.dialog.open(AppointmentModalComponent);
    dialogRef.componentInstance.data = {
      appointmentToUpdate: appointment,
      isEdit: true
    }

    dialogRef.afterClosed().subscribe(result => {
      this.getAllAppointments();
    });
  }

  getAllAppointments(): void {
    this.appointmentService.getAppointments().subscribe(appointments => {
      this.appointments = appointments;
    })
  }

  getAllMedics(): void {
    this.medicService.getMedics().subscribe(medics => {
      this.medics = medics;
    })
  }

  getAllClinics(): void {
    this.clinicService.getClinics().subscribe(clinics => {
      this.clinics = clinics;
    })
  }
}
