import { Component, OnChanges, OnInit, SimpleChanges, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Patient } from '../model/patient';
import { MatDialog } from '@angular/material/dialog';
import { PatientModalComponent } from '../patient-modal/patient-modal.component';
import { AppointmentModalComponent } from '../appointment-modal/appointment-modal.component';
import { NoteModalComponent } from '../note-modal/note-modal.component';
import { PatientService } from '../../services/patient.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss']
})
export class PatientComponent implements OnInit, OnChanges{

  currentPatient: Patient = {
    lastName: "-",
    firstName: "-",
    cnp: "-",
    phone: "-",
    email: "-",
    birthdate: null,
    gender: "-",
    country: "-",
    county: "-",
    city: "-",
    street: "-",
    ownerLastName: "-",
    ownerFirstName: "-",
    ownerPhone: "-",
    familyName: "-",
    familyMember: "-",
    language: "-",
    secondaryContact: "-",
    smsNotifications: false,
    emailNotifications: false,
    smsMarketing: false,
    emailMarketing: false,
    phoneMarketing: false,
    clinic: null,
    doctor: null
  }

  disablePatientSubMenu: boolean = true;

  patients: Patient[] = [];

  constructor(@Inject(DOCUMENT) document: Document, private router: Router,
    public dialog: MatDialog, 
    private patientService: PatientService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.getPatients();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getPatients();
  }

  getPatients():void {
    this.patientService.getPatients().subscribe(patients => {
      this.patients = patients;
    })
  }

  selectRow(patient:Patient): void {
    this.currentPatient = patient;
    this.disablePatientSubMenu = false;
  }

  editPatient(patient: Patient): void {
    const dialogRef = this.dialog.open(PatientModalComponent);
    dialogRef.componentInstance.data = {
      patientToUpdate: patient,
      isEdit: true
    }

    dialogRef.afterClosed().subscribe(result => {
      this.getPatients();
    });
  }

  addPatient(): void {
    const dialogRef = this.dialog.open(PatientModalComponent);
    dialogRef.componentInstance.data = {
      patientToUpdate: { 
        lastName: "-",
        firstName: "-",
        cnp: "-",
        phone: "-",
        email: "-",
        birthdate: null,
        gender: "-",
        country: "-",
        county: "-",
        city: "-",
        street: "-",
        ownerLastName: "-",
        ownerFirstName: "-",
        ownerPhone: "-",
        familyName: "-",
        familyMember: "-",
        language: "-",
        secondaryContact: "-",
        smsNotifications: false,
        emailNotifications: false,
        smsMarketing: false,
        emailMarketing: false,
        phoneMarketing: false,
        clinic: null,
        doctor: null
      },
      isEdit: false
    }

    dialogRef.afterClosed().subscribe(result => {
      this.getPatients();
    });
  }

  addAppointment(): void {
    const dialogRef = this.dialog.open(AppointmentModalComponent);
    dialogRef.componentInstance.data = {
      appointmentToUpdate: {
        doctor: null,
        clinic: null,
        patient: this.currentPatient,
        date: null,
        hour: "-",
        duration: "60 min",
        information: "",
        blocked: false,
        observations: "",
        confirmed: false,
        arrived: false,
        finalized: false
      },
      isEdit: false
    }

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  deletePatient(patient: Patient): void {
    this.patientService.deletePatient(patient.id ? patient.id : 0).subscribe(patient => {
      this.getPatients();
    });
  }

  addNote(): void {
    const dialogRef = this.dialog.open(NoteModalComponent);
    dialogRef.componentInstance.data = {
      noteToUpdate: {
          user: "",
          note: "",
          patient: this.currentPatient,
          date: null
      }, 
      isEdit: false
    }

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }
}
