import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { PatientModalComponent } from './patient-modal/patient-modal.component';
import { PatientService } from '../services/patient.service';
import { PFilterPipe } from './services/filter.pipe';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Patient } from './model/patient';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('true', style({ width: '25%' })), // Adjust the expanded width as needed
      state('false', style({ width: '7%' })), // Adjust the collapsed width as needed
      transition('true <=> false', animate('0.1s ease-in-out'))
    ])
  ]
})
export class UserComponent implements OnInit{
  menuState: boolean = false;
  subMenuState: boolean = false;

  searchText: string = '';
  isSearchOpen: boolean = false;

  patientsList: Patient[] = [];

  constructor(@Inject(DOCUMENT) document: Document, 
    private router: Router, public dialog: MatDialog,
    private patientService: PatientService) {}

  ngOnInit(): void {
    
  }

  toggleMenu() {
    this.menuState = !this.menuState;
    if (!this.menuState) {
      document.getElementById('logo')?.classList.add('logo-collapsed');
    } else {
      document.getElementById('logo')?.classList.remove('logo-collapsed');
    }
  }

  toggleSubMenu() {
    this.subMenuState = !this.subMenuState;
  }

  //TODO: create search bar
  showSearch() {
    this.patientService.getPatients().subscribe(result => {
      this.patientsList = result;
      this.isSearchOpen = true;
    })
  }

  closeSearch() {
    this.isSearchOpen = false;
  }

  navigateToPatient(p: Patient) {
    this.router.navigate(['./pacienti', p.id, 'contact']).then(() => {
      window.location.reload();
    })
  }

  addPatient() {
    const dialogRef = this.dialog.open(PatientModalComponent);
    dialogRef.componentInstance.data = {
      patientToUpdate: {
        lastName: "-",
        firstName: "-",
        cnp: "-",
        phone: "-",
        email: "-",
        birthdate: null,
        gender: "-",
        country: "-",
        county: "-",
        city: "-",
        street: "-",
        ownerLastName: "-",
        ownerFirstName: "-",
        ownerPhone: "-",
        familyName: "-",
        familyMember: "-",
        language: "-",
        secondaryContact: "-",
        smsNotifications: false,
        emailNotifications: false,
        smsMarketing: false,
        emailMarketing: false,
        phoneMarketing: false,
        clinic: null,
        doctor: null
      },
      isEdit: false
    }

    dialogRef.afterClosed().subscribe(result => {});
  }
}