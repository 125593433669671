import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class UtilsService {

    constructor( private router: Router ) {}

    sessionExpired(): void {
        window.localStorage.removeItem("firstName");
        window.localStorage.removeItem("lastName");
        window.localStorage.removeItem("email");
        window.localStorage.removeItem("role");
        window.localStorage.removeItem("auth_token");
        this.router.navigate(['/login']);
    };
}