import { Component, OnChanges, OnInit, SimpleChanges, Inject } from '@angular/core';
import { DOCUMENT, DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { NoteModalComponent } from '../note-modal/note-modal.component';
import { PatientNote } from '../model/patientNote';
import { Patient } from '../model/patient';
import { PatientService } from '../../services/patient.service';
import { PatientNotesService } from '../../services/patient.notes.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-patient-notes',
  templateUrl: './patient-notes.component.html',
  styleUrls: ['./patient-notes.component.scss']
})
export class PatientNotesComponent implements OnInit, OnChanges{

  patientId: string = '';
  notes: PatientNote[] = [];

  currentPatient: Patient = {
    lastName: "-",
    firstName: "-",
    cnp: "-",
    phone: "-",
    email: "-",
    birthdate: null,
    gender: "-",
    country: "-",
    county: "-",
    city: "-",
    street: "-",
    ownerLastName: "-",
    ownerFirstName: "-",
    ownerPhone: "-",
    familyName: "-",
    familyMember: "-",
    language: "-",
    secondaryContact: "-",
    smsNotifications: false,
    emailNotifications: false,
    smsMarketing: false,
    emailMarketing: false,
    phoneMarketing: false,
    clinic: null,
    doctor: null
  }

  constructor(@Inject(DOCUMENT) document: Document,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private patientNotesService: PatientNotesService
  ) {}

  ngOnInit(): void {
    this.getCurrentPatientId();
    this.getCurrentPatient();
    this.getNotesForPatient();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getNotesForPatient();
  }

  getCurrentPatientId() {
    this.route.parent?.params.subscribe(
      (params) => {
        this.patientId = params['id']
    });
  }

  getCurrentPatient() {
    this.patientService.getPatient(this.patientId).subscribe(
      patient => { this.currentPatient = patient }
    )
  }

  getNotesForPatient() {
    this.patientNotesService.getNotesForPatient(this.patientId).subscribe(
      notes => { this.notes = notes }
    )
  }

  editNote(note: PatientNote) {
    const dialogRef = this.dialog.open(NoteModalComponent);
    dialogRef.componentInstance.data = {
      noteToUpdate: note,
      isEdit: true
    }

    dialogRef.afterClosed().subscribe(result => {
      this.getNotesForPatient();
    });
  }

  deleteNote(note: PatientNote) {
    this.patientNotesService.deleteNote(note.id ? note.id : 0).subscribe( result => {
      this.getNotesForPatient();
    })
  }

  addNote(): void {
    const dialogRef = this.dialog.open(NoteModalComponent);
    dialogRef.componentInstance.data = {
      noteToUpdate: {
          user: "",
          note: "",
          patient: this.currentPatient,
          date: null
      }, 
      isEdit: false
    }

    dialogRef.afterClosed().subscribe(result => {
      this.getNotesForPatient();
    });
  }
}
