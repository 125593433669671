const host = 'http://176.126.172.225:10000';
// const host = 'http://localhost:10000';
const root = '/api/v1';
// const root = '/v1';

export const environment = {
    production: false,
    doctorUrl: host + root + '/doctors',
    patientUrl: host + root + '/patients',
    appointmentUrl: host + root + '/appointments',
    clinicUrl: host + root + '/clinics',
    treatmentCategoryUrl: host + root + '/treatment/categories',
    treatmentUrl: host + root + '/treatments',
    patientNotesUrl: host + root + '/patientnotes',
    patientTreatmentsUrl: host + root + '/patienttreatments',
    teethMapUrl: host + root + '/teethmap',
    loginUrl: host + root + '/users/login',
    registerUrl: host + root + '/users/register',
};
