import { Pipe, PipeTransform } from "@angular/core";
import { Patient } from "../model/patient";

@Pipe({
    name: 'pfilter'
})
export class PFilterPipe implements PipeTransform {
    transform(patients: Patient[], searchText: string): any[] {
        if(!patients) return [];
        if(!searchText) return patients;
    searchText = searchText.toLowerCase();
    return patients.filter( p => {
          return p.lastName.toLowerCase().includes(searchText) || p.firstName.toLowerCase().includes(searchText);
        });
    }
}