import { Component, Input, OnInit, Inject } from '@angular/core';
import { DOCUMENT, DatePipe } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { PatientTreatment } from '../model/patientTreatment';
import { ToastrService } from 'ngx-toastr';
import { Treatment } from '../model/treatment';
import { Clinic } from '../model/clinic';
import { Medic } from '../model/medic';
import { TreatmentService } from '../../services/treatment.service';
import { ClinicService } from '../../services/clinic.service';
import { MedicService } from '../../services/medic.service';
import { PatientTreatmentService } from '../../services/patient.treatment.service';
import { ToothPhoto } from '../patient-treatment/patient-treatment.component';
import { PatientTeethMap } from '../model/patientTeethMap';
import { PatientTeethMapService } from '../../services/patient.teeth.map.service';

@Component({
  selector: 'app-patient-treatment-modal',
  templateUrl: './patient-treatment-modal.component.html',
  styleUrls: ['./patient-treatment-modal.component.scss']
})
export class PatientTreatmentModalComponent implements OnInit{

  @Input() data: {
    patientTreatmentToUpdate: PatientTreatment,
    isEdit: boolean
  }

  // teeth map
  teethPhotos: ToothPhoto[] = [];

  // title
  formTitle: string = "ADAUGA TRATAMENT PACIENT";
  formTitleIcon: String = "addthis";
  holdingBtn: boolean = false;

  // Date
  currentDate: Date = new Date();
  toothAreaOptions: string[] = [
    "Vestibulara (V)", "Linguala (L)", "Palatinala (P)", 
    "Meziala (M)", "Distala (D)", "Ocluzala (O)"];

  statusOptions: string[] = [
    "Propus", "In derulare", "Finalizat"
  ];

  recallOptions: string[] = [
    "saptamani", "luni"
  ]

  currentPatientTreatment: PatientTreatment;
  treatments: Treatment[] = [];
  clinics: Clinic[] = [];
  medics: Medic[] = [];

  patientTeethMap: PatientTeethMap;

  constructor(
    @Inject(DOCUMENT) document: Document,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<string>,
    private treatmentService: TreatmentService,
    private clinicService: ClinicService,
    private medicService: MedicService,
    private patientTreatmentService: PatientTreatmentService,
    private patientTeethMapService: PatientTeethMapService,
    private toastr: ToastrService) {
      this.data = {
        patientTreatmentToUpdate: {
          treatment: null,
          date: null,
          teeth: [],
          doctor: null,
          toothArea: "",
          clinic: null,
          price: 0,
          percentDiscount: 0,
          negotiatedPrice: 0,
          status: "",
          recallNumber: 0,
          recallType: "",
          observations: "",
          atribut: "",
          patient: null
        }, 
        isEdit: false
      }
      this.currentPatientTreatment = this.data.patientTreatmentToUpdate;

      this.patientTeethMap = {
        patient: this.currentPatientTreatment.patient,
        tooth18: "",
        tooth17: "",
        tooth16: "",
        tooth15: "",
        tooth14: "",
        tooth13: "",
        tooth12: "",
        tooth11: "",
        tooth21: "",
        tooth22: "",
        tooth23: "",
        tooth24: "",
        tooth25: "",
        tooth26: "",
        tooth27: "",
        tooth28: "",
        tooth38: "",
        tooth37: "",
        tooth36: "",
        tooth35: "",
        tooth34: "",
        tooth33: "",
        tooth32: "",
        tooth31: "",
        tooth41: "",
        tooth42: "",
        tooth43: "",
        tooth44: "",
        tooth45: "",
        tooth46: "",
        tooth47: "",
        tooth48: ""
      };
    }

  ngOnInit(): void {
    this.getTreatments();
    this.getMedics();
    this.getClinics();

    if (this.data.isEdit) {
      this.currentPatientTreatment = this.data.patientTreatmentToUpdate;

      document.getElementById('form-title')?.classList.add('form-title-edit');
      this.formTitle = "EDITEAZA TRATAMENT PACIENT";
      this.formTitleIcon = "pencil";
    } else {
      this.currentPatientTreatment = {
        treatment: null,
        date: this.currentDate,
        teeth: [],
        doctor: this.data.patientTreatmentToUpdate.patient?.doctor,
        toothArea: "",
        clinic: this.data.patientTreatmentToUpdate.patient?.clinic,
        price: 0,
        percentDiscount: 0,
        negotiatedPrice: 0,
        status: "",
        recallNumber: 0,
        recallType: "",
        observations: "",
        atribut: "",
        patient: this.data.patientTreatmentToUpdate.patient
      }
    }
    this.getPatientTeethMap();
  }

  getTreatments() {
    this.treatmentService.getTreatments().subscribe( treatments => {
      this.treatments = treatments;
    })
  }

  getMedics() {
    this.medicService.getMedics().subscribe(medics => {
      this.medics = medics;
    })
  }

  getClinics() {
    this.clinicService.getClinics().subscribe(clinics => {
      this.clinics = clinics;
    })
  }

  addPatientTreatment() {
    this.currentPatientTreatment.date = this.datePipe.transform(this.currentPatientTreatment.date, 'yyyy-MM-dd HH:mm:ss');
    this.patientTreatmentService.addPatientTreatment(this.currentPatientTreatment).subscribe(response => {

    }, error => {

    })
    this.dialogRef.close();
  }

  updatePatientTreatment() {
    this.currentPatientTreatment.date = this.datePipe.transform(this.currentPatientTreatment.date, 'yyyy-MM-dd HH:mm:ss');
    this.patientTreatmentService.updatePatientTreatment(this.currentPatientTreatment).subscribe(response => {

    }, error => {

    })
    this.dialogRef.close();
  }



  onPriceChange() {
    this.currentPatientTreatment.price = this.currentPatientTreatment.treatment?.price !== undefined ? this.currentPatientTreatment.treatment?.price : 0;
    if (this.currentPatientTreatment.treatment?.pricePer === 'Dinte') {
      this.currentPatientTreatment.price = this.currentPatientTreatment.price * this.currentPatientTreatment.teeth.length;
    } else if (this.currentPatientTreatment.treatment?.pricePer === 'Arcada') {
      this.currentPatientTreatment.price = this.currentPatientTreatment.price * 8;
    } else if (this.currentPatientTreatment.treatment?.pricePer === 'Sedinta') {
      // this.currentPatientTreatment.price = 
    }
  }

  applyPercentageDiscount() {
    this.currentPatientTreatment.negotiatedPrice = this.currentPatientTreatment.price - (this.currentPatientTreatment.percentDiscount/100 * this.currentPatientTreatment.price);
  }

  getPercentageFromPrice() {
    this.currentPatientTreatment.percentDiscount = (this.currentPatientTreatment.price - this.currentPatientTreatment.negotiatedPrice)/this.currentPatientTreatment.price * 100;
  }

  save(): void {
    if (this.data.isEdit) {
      this.updatePatientTreatment();
    } else {
      this.addPatientTreatment();
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  // Teeth Map methods
  getPatientTeethMap(): void {
    this.patientTeethMapService.getTeethMapForPatient(this.currentPatientTreatment.patient?.id ? this.currentPatientTreatment.patient?.id.toString() : "").subscribe(
      teethMap => { 
        this.patientTeethMap = teethMap;
        this.fetchPhotos();
      }
    )
  }

  fetchPhotos(): void {
    this.teethPhotos.push(new ToothPhoto("18", `../../../assets/images/teeth/${this.patientTeethMap.tooth18}.png`, true));
    this.teethPhotos.push(new ToothPhoto("17", `../../../assets/images/teeth/${this.patientTeethMap.tooth17}.png`, true));
    this.teethPhotos.push(new ToothPhoto("16", `../../../assets/images/teeth/${this.patientTeethMap.tooth16}.png`, true));
    this.teethPhotos.push(new ToothPhoto("15", `../../../assets/images/teeth/${this.patientTeethMap.tooth15}.png`, true));
    this.teethPhotos.push(new ToothPhoto("14", `../../../assets/images/teeth/${this.patientTeethMap.tooth14}.png`, true));
    this.teethPhotos.push(new ToothPhoto("13", `../../../assets/images/teeth/${this.patientTeethMap.tooth13}.png`, true));
    this.teethPhotos.push(new ToothPhoto("12", `../../../assets/images/teeth/${this.patientTeethMap.tooth12}.png`, true));
    this.teethPhotos.push(new ToothPhoto("11", `../../../assets/images/teeth/${this.patientTeethMap.tooth11}.png`, true));
    this.teethPhotos.push(new ToothPhoto("21", `../../../assets/images/teeth/${this.patientTeethMap.tooth21}.png`, true));
    this.teethPhotos.push(new ToothPhoto("22", `../../../assets/images/teeth/${this.patientTeethMap.tooth22}.png`, true));
    this.teethPhotos.push(new ToothPhoto("23", `../../../assets/images/teeth/${this.patientTeethMap.tooth23}.png`, true));
    this.teethPhotos.push(new ToothPhoto("24", `../../../assets/images/teeth/${this.patientTeethMap.tooth24}.png`, true));
    this.teethPhotos.push(new ToothPhoto("25", `../../../assets/images/teeth/${this.patientTeethMap.tooth25}.png`, true));
    this.teethPhotos.push(new ToothPhoto("26", `../../../assets/images/teeth/${this.patientTeethMap.tooth26}.png`, true));
    this.teethPhotos.push(new ToothPhoto("27", `../../../assets/images/teeth/${this.patientTeethMap.tooth27}.png`, true));
    this.teethPhotos.push(new ToothPhoto("28", `../../../assets/images/teeth/${this.patientTeethMap.tooth28}.png`, true));
    this.teethPhotos.push(new ToothPhoto("48", `../../../assets/images/teeth/${this.patientTeethMap.tooth48}.png`, false));
    this.teethPhotos.push(new ToothPhoto("47", `../../../assets/images/teeth/${this.patientTeethMap.tooth47}.png`, false));
    this.teethPhotos.push(new ToothPhoto("46", `../../../assets/images/teeth/${this.patientTeethMap.tooth46}.png`, false));
    this.teethPhotos.push(new ToothPhoto("45", `../../../assets/images/teeth/${this.patientTeethMap.tooth45}.png`, false));
    this.teethPhotos.push(new ToothPhoto("44", `../../../assets/images/teeth/${this.patientTeethMap.tooth44}.png`, false));
    this.teethPhotos.push(new ToothPhoto("43", `../../../assets/images/teeth/${this.patientTeethMap.tooth43}.png`, false));
    this.teethPhotos.push(new ToothPhoto("42", `../../../assets/images/teeth/${this.patientTeethMap.tooth42}.png`, false));
    this.teethPhotos.push(new ToothPhoto("41", `../../../assets/images/teeth/${this.patientTeethMap.tooth41}.png`, false));
    this.teethPhotos.push(new ToothPhoto("31", `../../../assets/images/teeth/${this.patientTeethMap.tooth31}.png`, false));
    this.teethPhotos.push(new ToothPhoto("32", `../../../assets/images/teeth/${this.patientTeethMap.tooth32}.png`, false));
    this.teethPhotos.push(new ToothPhoto("33", `../../../assets/images/teeth/${this.patientTeethMap.tooth33}.png`, false));
    this.teethPhotos.push(new ToothPhoto("34", `../../../assets/images/teeth/${this.patientTeethMap.tooth34}.png`, false));
    this.teethPhotos.push(new ToothPhoto("35", `../../../assets/images/teeth/${this.patientTeethMap.tooth35}.png`, false));
    this.teethPhotos.push(new ToothPhoto("36", `../../../assets/images/teeth/${this.patientTeethMap.tooth36}.png`, false));
    this.teethPhotos.push(new ToothPhoto("37", `../../../assets/images/teeth/${this.patientTeethMap.tooth37}.png`, false));
    this.teethPhotos.push(new ToothPhoto("38", `../../../assets/images/teeth/${this.patientTeethMap.tooth38}.png`, false));
  }

  selectTeeth(id: string): void {
    // document.getElementById(id)?.classList.remove('highlighted');
    if (this.currentPatientTreatment.teeth.includes(id)) {
      let index = this.currentPatientTreatment.teeth.indexOf(id);
      // this.selectedTeeth.splice(index, 1);
      this.currentPatientTreatment.teeth.splice(index, 1);
      document.getElementById(id)?.classList.remove('highlighted');
    } else {
      document.getElementById(id)?.classList.add('highlighted');
      // this.selectedTeeth.push(id);
      this.currentPatientTreatment.teeth.push(id);
    }
    this.onPriceChange();
  }

  selectAllTeeth(): void {
    this.currentPatientTreatment.teeth = [];
    for (let id of ["18","17", "16", "15", "14", "13", "12", "11",
                    "21", "22", "23", "24", "25", "26", "27", "28",
                    "48", "47", "46", "45", "44", "43", "42", "41",
                    "31", "32", "33", "34", "35", "36", "37", "38"]) {
        document.getElementById(id)?.classList.add('highlighted');
        // this.selectedTeeth.push(id);
        this.currentPatientTreatment.teeth.push(id);
    }
    this.onPriceChange();
  }

  deselectAllTeeth(): void {
    const tmpElements: any = this.currentPatientTreatment.teeth;
    for (let index = 0; index < tmpElements.length; index++) {
      document.getElementById(tmpElements[index])?.classList.remove('highlighted');
    }
    this.currentPatientTreatment.teeth = [];
    this.onPriceChange();
  }
}
