import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.development';
import { Appointment } from '../user/model/appointment';
import { AuthService } from './auth.service';
import { UtilsService } from './utils.service';
import { EMPTY, Observable, catchError, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class AppointmentService {

    headers: HttpHeaders = new HttpHeaders({"Content-Type":"application/json"});

    constructor(private http: HttpClient, private authService: AuthService,
        private utilsService: UtilsService
    ) {}

    getAppointments(): Observable<Appointment[]> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.get<Appointment[]>(environment.appointmentUrl, {headers: this.headers})
            .pipe(
                map(appointments => appointments.map(
                    appointment => (
                        {
                            id: appointment.id,
                            doctor: appointment.doctor,
                            clinic: appointment.clinic,
                            patient: appointment.patient,
                            date: appointment.date,
                            hour: appointment.hour,
                            duration: appointment.duration,
                            information: appointment.information,
                            blocked: appointment.blocked,
                            observations: appointment.observations,
                            confirmed: appointment.confirmed,
                            arrived: appointment.arrived,
                            finalized: appointment.finalized
                        }
                    )
                )),
                catchError((err, caught) => {
                    if (err.status === 403 || err.status === 401) {
                        this.utilsService.sessionExpired();
                    }
                    console.log(err.status);
                    return EMPTY;
                })
            );
    }

    updateAppointment(appointment: Appointment): Observable<Appointment> {
        
        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.put<Appointment>(
            environment.appointmentUrl + "/" + (appointment.id ? appointment.id : ''),
            appointment, {headers: this.headers});
    }

    addAppointment(appointment: Appointment): Observable<Appointment> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.post<Appointment>(environment.appointmentUrl, appointment, {headers: this.headers});
    }

    deleteAppointment(id: number): Observable<number> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.delete<number>(
            environment.appointmentUrl + "/" + (id ? id : 0), {headers: this.headers})
    }

    getAppointmentsForPatient(id: string | null): Observable<Appointment[]> {
        let pathParam;
        if (id !== null) {
            pathParam = Number(id);
        }

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.get<Appointment[]>(environment.appointmentUrl + '/patient/' + pathParam, {headers: this.headers})
            .pipe(
                map(appointments => appointments.map(
                    appointment => (
                        {
                            id: appointment.id,
                            doctor: appointment.doctor,
                            clinic: appointment.clinic,
                            patient: appointment.patient,
                            date: appointment.date,
                            hour: appointment.hour,
                            duration: appointment.duration,
                            information: appointment.information,
                            blocked: appointment.blocked,
                            observations: appointment.observations,
                            confirmed: appointment.confirmed,
                            arrived: appointment.arrived,
                            finalized: appointment.finalized
                        }
                    )
                )),
                catchError((err, caught) => {
                    if (err.status === 403 || err.status === 401) {
                        this.utilsService.sessionExpired();
                    }
                    console.log(err.status);
                    return EMPTY;
                })
            );
    }
}