import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.development';
import { AuthService } from './auth.service';
import { UtilsService } from './utils.service';
import { EMPTY, Observable, catchError, map } from 'rxjs';
import { PatientTreatment } from '../user/model/patientTreatment';

@Injectable({
    providedIn: 'root'
})

export class PatientTreatmentService {
    headers: HttpHeaders = new HttpHeaders({"Content-Type":"application/json"});

    constructor(private http: HttpClient, private authService: AuthService,
        private utilsService: UtilsService
    ) {}

    getAllTreatments(): Observable<PatientTreatment[]> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.get<PatientTreatment[]>(environment.patientTreatmentsUrl, {headers: this.headers})
            .pipe(
                map(treatments => treatments.map(
                    treatment => (
                        {
                            id: treatment.id,
                            treatment: treatment.treatment,
                            date: treatment.date,
                            teeth: treatment.teeth,
                            doctor: treatment.doctor,
                            toothArea: treatment.toothArea,
                            clinic: treatment.clinic,
                            price: treatment.price,
                            percentDiscount: treatment.percentDiscount,
                            negotiatedPrice: treatment.negotiatedPrice,
                            status: treatment.status,
                            recallNumber: treatment.recallNumber,
                            recallType: treatment.recallType,
                            observations: treatment.observations,
                            atribut: treatment.atribut,
                            patient: treatment.patient
                        }
                    )
                )),
                catchError((err, caught) => {
                    if (err.status === 403 || err.status === 401) {
                        this.utilsService.sessionExpired();
                    }
                    console.log(err.status);
                    return EMPTY;
                })
            );
    }

    updatePatientTreatment(note: PatientTreatment): Observable<PatientTreatment> {
        
        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.put<PatientTreatment>(
            environment.patientTreatmentsUrl + "/" + (note.id ? note.id : ''),
            note, {headers: this.headers});
    }

    addPatientTreatment(note: PatientTreatment): Observable<PatientTreatment> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.post<PatientTreatment>(environment.patientTreatmentsUrl, note, {headers: this.headers});
    }

    deletePatientTreatment(id: number): Observable<number> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.delete<number>(
            environment.patientTreatmentsUrl + "/" + (id ? id : 0), {headers: this.headers})
    }

    getTreatmentsForPatient(id: string | null): Observable<PatientTreatment[]> {
        let pathParam;
        if (id !== null) {
            pathParam = Number(id);
        }

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.get<PatientTreatment[]>(environment.patientTreatmentsUrl + '/patient/' + pathParam, {headers: this.headers})
            .pipe(
                map(treatments => treatments.map(
                    treatment => (
                        {
                            id: treatment.id,
                            treatment: treatment.treatment,
                            date: treatment.date,
                            teeth: treatment.teeth,
                            doctor: treatment.doctor,
                            toothArea: treatment.toothArea,
                            clinic: treatment.clinic,
                            price: treatment.price,
                            percentDiscount: treatment.percentDiscount,
                            negotiatedPrice: treatment.negotiatedPrice,
                            status: treatment.status,
                            recallNumber: treatment.recallNumber,
                            recallType: treatment.recallType,
                            observations: treatment.observations,
                            atribut: treatment.atribut,
                            patient: treatment.patient
                        }
                    )
                )),
                catchError((err, caught) => {
                    if (err.status === 403 || err.status === 401) {
                        this.utilsService.sessionExpired();
                    }
                    console.log(err.status);
                    return EMPTY;
                })
            );
    }
}