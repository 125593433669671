import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRoutingModule } from './user.routing.module';
import { HomeComponent } from './home/home.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { MedicComponent } from './medic/medic.component';
import { PatientComponent } from './patient/patient.component';
import { UserComponent } from './user.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PatientModalComponent } from './patient-modal/patient-modal.component';
import { FormsModule } from '@angular/forms';
import { AppointmentModalComponent } from './appointment-modal/appointment-modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { SpeechRecognitionService } from './services/speech-recognition.service';
import { MedicModalComponent } from './medic-modal/medic-modal.component';
import { NoteModalComponent } from './note-modal/note-modal.component';
import { PatientShortModalComponent } from './patient-short-modal/patient-short-modal.component';
import { ClinicComponent } from './clinic/clinic.component';
import { ClinicModalComponent } from './clinic-modal/clinic-modal.component';
import { TreatmentComponent } from './treatment/treatment.component';
import { TreatmentModalComponent } from './treatment-modal/treatment-modal.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip'; 
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { TreatmentCategoryComponent } from './treatment-category/treatment-category.component';
import { TreatmentCategoryModalComponent } from './treatment-category-modal/treatment-category-modal.component';
import { PatientMainComponent } from './patient-main/patient-main.component';
import { PatientTreatmentComponent } from './patient-treatment/patient-treatment.component';
import { PatientAppointmentComponent } from './patient-appointment/patient-appointment.component';
import { PatientContactComponent } from './patient-contact/patient-contact.component';
import { PatientNotesComponent } from './patient-notes/patient-notes.component';
import { PatientTreatmentModalComponent } from './patient-treatment-modal/patient-treatment-modal.component';
import { PFilterPipe } from './services/filter.pipe';
import { OverlayModule } from '@angular/cdk/overlay';
import { ShortcutsContainerComponent } from './shortcuts-container/shortcuts-container.component';




@NgModule({
  declarations: [
    UserComponent,
    HomeComponent,
    AppointmentComponent,
    MedicComponent,
    PatientComponent,
    SvgIconComponent,
    PatientModalComponent,
    AppointmentModalComponent,
    MedicModalComponent,
    NoteModalComponent,
    PatientShortModalComponent,
    ClinicComponent,
    ClinicModalComponent,
    TreatmentComponent,
    TreatmentModalComponent,
    TreatmentCategoryComponent,
    TreatmentCategoryModalComponent,
    PatientMainComponent,
    PatientTreatmentComponent,
    PatientAppointmentComponent,
    PatientContactComponent,
    PatientNotesComponent,
    PatientTreatmentModalComponent,
    PFilterPipe,
    ShortcutsContainerComponent
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    MatDialogModule,
    FormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatGridListModule,
    MatCardModule,
    MatRadioModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    MatIconModule,
    MatToolbarModule,
    MatDividerModule,
    MatListModule,
    OverlayModule
  ],
  exports: [
    SvgIconComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-GB' },
    SpeechRecognitionService
  ]
})
export class UserModule { }
