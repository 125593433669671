import { LOCALE_ID, Input, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { SpeechRecognitionService } from '../services/speech-recognition.service';
import { Patient } from '../model/patient';
import { PatientNote } from '../model/patientNote';
import { PatientNotesService } from '../../services/patient.notes.service';

@Component({
  selector: 'app-note-modal',
  templateUrl: './note-modal.component.html',
  styleUrls: ['./note-modal.component.scss']
})
export class NoteModalComponent implements OnInit{

  @Input() data: {
    noteToUpdate: PatientNote,
    isEdit: boolean
  }

  // calendar variables
  currentDate: Date = new Date();

  currentNote: PatientNote;

  //TODO: properly set user for notes
  user: String | null = window.localStorage.getItem("lastName");

  holdingBtn: boolean = false;

  constructor(@Inject(LOCALE_ID) public locale: string, 
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<NoteModalComponent>,
    private speechRecognitionService: SpeechRecognitionService,
    private patientNotesService: PatientNotesService){
      this.data = {
        noteToUpdate: {
          user: "",
          note: "",
          patient: null,
          date: null
        }, 
        isEdit: false
      }
      this.currentNote = this.data.noteToUpdate;
  }
  
  ngOnInit(): void {
    if (this.data.isEdit) {
      this.currentNote = this.data.noteToUpdate;
    } else {
      this.currentNote = {
        user: this.user,
        note: "",
        patient: this.data.noteToUpdate.patient,
        date: this.currentDate
      }
    }
  }

  async startSpeechRecognition() {
    try {
      this.currentNote.note = this.currentNote.note + await this.speechRecognitionService.startRecognition();
    } catch (error) {
      console.error('Speech recognition error:', error);
    }
  }

  onSpeechRecBtn() {
    this.holdingBtn = true;
  }

  stopSpeechRecognition() {
    this.speechRecognitionService.stopRecognition();
    this.holdingBtn = false;
  }

  addNote() {
    this.currentNote.date = this.datePipe.transform(this.currentNote.date, 'yyyy-MM-dd HH:mm:ss');
    this.patientNotesService.addNote(this.currentNote).subscribe(response => {

    }, error => {

    })
    this.dialogRef.close();
  }

  updateNote() {
    this.currentNote.date = this.datePipe.transform(this.currentNote.date, 'yyyy-MM-dd HH:mm:ss');
    this.patientNotesService.updateNote(this.currentNote).subscribe(response => {

    }, error => {

    })
    this.dialogRef.close();
  }

  save(): void {
    if (this.data.isEdit) {
      this.updateNote();
    } else {
      this.addNote();
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
