import { Component, Input, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { TreatmentService } from '../../services/treatment.service';
import { TreatmentCategoryService } from '../../services/treatment.category.service';
import { Treatment } from '../model/treatment';
import { TreatmentCategory } from '../model/treatmentCategory';
import { ToastrService } from 'ngx-toastr';

interface Photo {
  name: string;
  url: string;
  selected: boolean;
}

@Component({
  selector: 'app-treatment-modal',
  templateUrl: './treatment-modal.component.html',
  styleUrls: ['./treatment-modal.component.scss']
})

export class TreatmentModalComponent implements OnInit{

  @Input() data: {
    treatmentToUpdate: Treatment,
    isEdit: boolean
  }

  // title
  formTitle: string = "ADAUGA TRATAMENT";
  formTitleIcon: String = "addthis";

  // selected items
  currentTreatment: Treatment;
  treatmentCategories: TreatmentCategory[] = [];
  // selectedPhoto: string = "";

  pricePerOptions: string[] = ['Dinte', 'Arcada', 'Sedinta'];

  photos: Photo[] = [];
  imagesNames: string[] = [
    'coronita', 'dinte', 'extractie', 'implant', 
    'obturatie', 'rest-radicular', 'retratament-pe-canal',
  ]

  constructor(
    @Inject(DOCUMENT) document: Document,
    public dialogRef: MatDialogRef<string>,
    private treatmentService: TreatmentService,
    private treatmentCategoryService: TreatmentCategoryService,
    private toastr: ToastrService) {
      this.data = {
        treatmentToUpdate: {
          treatmentName: "",
          treatmentCategory: null,
          price: 0,
          materialPrice: 0,
          pricePer: "",
          iconName: ""
        }, 
        isEdit: false
      }
      this.currentTreatment = this.data.treatmentToUpdate;
    }

  ngOnInit(): void {
    this.getTreatmentCategories();
    this.currentTreatment = this.data.treatmentToUpdate;
    this.fetchPhotos();
    if (this.data.isEdit) {
      document.getElementById('form-title')?.classList.add('form-title-edit');
      this.formTitle = "EDITEAZA TRATAMENT";
      this.formTitleIcon = "pencil";
    }
  }

  fetchPhotos(): void {
    this.imagesNames.forEach((imageName) => {
      var photo: Photo = {
        name: `${imageName}`,
        url: `../../../assets/images/teeth/${imageName}.png`,
        selected: false
      };
      if (photo.name === this.currentTreatment.iconName) {
        photo.selected = true;
      }
      this.photos.push(photo);
    });
  }

  selectPhoto(photo: Photo): void {
    document.getElementById(this.currentTreatment.iconName)?.classList.remove('highlighted');
    photo.selected = !photo.selected;
    this.currentTreatment.iconName = photo.name;
    document.getElementById(photo.name)?.classList.add('highlighted');
  }

  getTreatmentCategories(): void {
    this.treatmentCategoryService.getTreatmentCategories().subscribe(treatmentCategories => {
      this.treatmentCategories = treatmentCategories;
    })
  }

  // CRUD operations
  addTreatment(): void {
    if (this.isValidTreatment()) {
      this.treatmentService.addTreatment(this.currentTreatment).subscribe(response => {
        // this.toastr.success('Medic added successfully');
      }, error => {
        // this.toastr.error('Failed to add medic');
      })
      this.dialogRef.close();
    }
  }

  updateTreatment(): void {
    if (this.isValidTreatment()) {
      this.treatmentService.updateTreatment(this.currentTreatment).subscribe(response => {
        // this.toastr.success('Medic updated successfully');
      }, error => {
        // this.toastr.error('Failed to update medic');
      })
      this.dialogRef.close();
    }
  }

  isValidTreatment(): boolean {
    // VALIDATE INPUT FIELDS
    if (this.currentTreatment.treatmentName === null || 
      this.currentTreatment.treatmentName === undefined) {
        document.getElementById('treatmentName')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('treatmentName')?.classList.remove('invalid-field');
    if (this.currentTreatment.treatmentCategory === null || 
      this.currentTreatment.treatmentCategory === undefined) {
        document.getElementById('categoryDropdown')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('categoryDropdown')?.classList.remove('invalid-field');
    if (this.currentTreatment.price === null || 
      this.currentTreatment.price === 0 ||
      this.currentTreatment.price === undefined) {
        document.getElementById('treatmentPrice')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('treatmentPrice')?.classList.remove('invalid-field');
    return true;
  }

  save(): void {
    if (this.data.isEdit) {
      this.updateTreatment();
    } else {
      this.addTreatment();
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
