<div class="patient-page">
    <div class="container left-container">
        <div class="patient-details-container">
            <div class="actions-details">
                <div class="spacer"></div>
                <button mat-flat-button [matMenuTriggerFor]="contact" class="action-btn" (click)="navigateTo('contact')">Contact</button>
                <mat-menu #contact="matMenu">
                  <button mat-menu-item (click)="navigateTo('notite')">Notite</button>
                  <button mat-menu-item>Corespondenta</button>
                  <button mat-menu-item>Documente</button>
                  <button mat-menu-item>Formulare</button>
                </mat-menu>
                <button mat-flat-button [matMenuTriggerFor]="appointments" class="action-btn" (click)="navigateTo('programari')">Programari</button>
                <mat-menu #appointments="matMenu">
                  <button mat-menu-item>Recalluri</button>
                  <button mat-menu-item>Vizita urmatoare</button>
                </mat-menu>
                <button mat-flat-button [matMenuTriggerFor]="treatments" class="action-btn" (click)="navigateTo('tratamente')">Tratamente</button>
                <mat-menu #treatments="matMenu">
                  <button mat-menu-item>Plan de tratament</button>
                  <button mat-menu-item>Istoric</button>
                  <button mat-menu-item>Paro chart</button>
                </mat-menu>
                <button mat-flat-button [matMenuTriggerFor]="medicalData" class="action-btn">Date med.</button>
                <mat-menu #medicalData="matMenu">
                  <button mat-menu-item>Anamneza</button>
                  <button mat-menu-item>Alerte medicale</button>
                  <button mat-menu-item>Radiografii</button>
                  <button mat-menu-item>Retete</button>
                </mat-menu>
                <button mat-flat-button [matMenuTriggerFor]="finance" class="action-btn">Financiar</button>
                <mat-menu #finance="matMenu">
                  <button mat-menu-item>Plati</button>
                  <button mat-menu-item>Facturi si chitante</button>
                  <button mat-menu-item>Credit</button>
                  <button mat-menu-item>Abonamente</button>
                </mat-menu>
            </div>
        
            <div class="patient-details-section-top">
                <div class="photo-placeholder">
                    Photo Placeholder
                </div>
                <div class="column1">
                  <div class="header">
                    {{currentPatient.lastName}} {{currentPatient.firstName}}
                  </div>
                  <div class="sub-header">
                    <span class="field">DOB </span><span>:{{currentPatient.lastName}}</span>
                  </div>
                  <div class="sub-header">
                    <span class="field">ID </span><span>:{{currentPatient.id}}</span>
                  </div>
                  <div class="sub-header">
                    <span class="field">Gen </span><span>:{{currentPatient.gender}}</span>
                  </div>
                  <div class="sub-header">
                    <span class="field">CNP </span><span>:{{currentPatient.cnp}}</span>
                  </div>
                </div>
                <div class="column1">
                  <div class="header2">
                    Contact Info
                  </div>
                  <div class="sub-header">
                    <span class="field">Telefon </span><span>:{{currentPatient.phone}}</span>
                  </div>
                  <div class="sub-header">
                    <span class="field">Email </span><span>:{{currentPatient.email}}</span>
                  </div>
                  <div class="sub-header">
                    <span class="field">Adresa </span><span>:{{currentPatient.city}}, {{currentPatient.street}}</span>
                  </div>
                </div>
                <div class="column1 column-end-right">
                  <small class="d-block"></small>
                  <button class="edit-btn" (click)="editPatient(this.currentPatient)">
                    <app-svg-icon class="svg-size" [path]="'../../assets/images/btns/pencil.svg'">
                    </app-svg-icon>
                    <div>Edit</div>
                  </button>
                </div>
            </div>
        </div>
        <div class="patient-navigate-container">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-shortcuts-container class="container right-container">
    </app-shortcuts-container>
</div>
