import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.development';
import { Patient } from '../user/model/patient';
import { AuthService } from './auth.service';
import { UtilsService } from './utils.service';
import { EMPTY, Observable, catchError, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PatientService {

    headers: HttpHeaders = new HttpHeaders({"Content-Type":"application/json"});

    constructor(private http: HttpClient, private authService: AuthService,
        private utilsService: UtilsService
    ) {}

    // TODO: map medics correctly
    getPatients(): Observable<Patient[]> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.get<Patient[]>(environment.patientUrl, {headers: this.headers})
            .pipe(
                map(patients => patients.map(
                    patient => (
                        {
                            id: patient.id,
                            lastName: patient.lastName,
                            firstName: patient.firstName,
                            cnp: patient.cnp,
                            phone: patient.phone,
                            email: patient.email,
                            birthdate: patient.birthdate,
                            gender: patient.gender,
                            country: patient.country,
                            county: patient.county,
                            city: patient.city,
                            street: patient.street,
                            ownerLastName: patient.ownerLastName,
                            ownerFirstName: patient.ownerFirstName,
                            ownerPhone: patient.ownerPhone,
                            familyName: patient.familyName,
                            familyMember: patient.familyMember,
                            language: patient.language,
                            secondaryContact: patient.secondaryContact,
                            smsNotifications: patient.smsNotifications,
                            emailNotifications: patient.emailNotifications,
                            smsMarketing: patient.smsMarketing,
                            emailMarketing: patient.emailMarketing,
                            phoneMarketing: patient.phoneMarketing,
                            clinic: patient.clinic,
                            doctor: patient.doctor
                        }
                    )
                )),
                catchError((err, caught) => {
                    if (err.status === 403 || err.status === 401) {
                        this.utilsService.sessionExpired();
                    }
                    console.log(err.status);
                    return EMPTY;
                })
            );
    }

    updatePatient(patient: Patient): Observable<Patient> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.put<Patient>(
            environment.patientUrl + "/" + (patient.id ? patient.id : ''),
            patient, {headers: this.headers});
    }

    addPatient(patient: Patient): Observable<Patient> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.post<Patient>(environment.patientUrl, patient, {headers: this.headers});
    }

    deletePatient(id: number): Observable<number> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.delete<number>(
            environment.patientUrl + "/" + (id ? id : 0), {headers: this.headers})
    }

    getPatient(id: string | null): Observable<Patient> {
        let pathParam;
        if (id !== null) {
            pathParam = Number(id);
        }
        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.get<Patient>(environment.patientUrl + '/' + pathParam, {headers: this.headers})
            .pipe(
                map (
                    patient => (
                        {
                            id: patient.id,
                            lastName: patient.lastName,
                            firstName: patient.firstName,
                            cnp: patient.cnp,
                            phone: patient.phone,
                            email: patient.email,
                            birthdate: patient.birthdate,
                            gender: patient.gender,
                            country: patient.country,
                            county: patient.county,
                            city: patient.city,
                            street: patient.street,
                            ownerLastName: patient.ownerLastName,
                            ownerFirstName: patient.ownerFirstName,
                            ownerPhone: patient.ownerPhone,
                            familyName: patient.familyName,
                            familyMember: patient.familyMember,
                            language: patient.language,
                            secondaryContact: patient.secondaryContact,
                            smsNotifications: patient.smsNotifications,
                            emailNotifications: patient.emailNotifications,
                            smsMarketing: patient.smsMarketing,
                            emailMarketing: patient.emailMarketing,
                            phoneMarketing: patient.phoneMarketing,
                            clinic: patient.clinic,
                            doctor: patient.doctor
                        }
                    )
                ),
                catchError((err, caught) => {
                    if (err.status === 403 || err.status === 401) {
                        this.utilsService.sessionExpired();
                    }
                    console.log(err.status);
                    return EMPTY;
                })
            );
    }
}