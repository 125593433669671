import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TreatmentCategoryModalComponent } from '../treatment-category-modal/treatment-category-modal.component';
import { TreatmentCategoryService } from '../../services/treatment.category.service';
import { TreatmentCategory } from '../model/treatmentCategory';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-treatment-category',
  templateUrl: './treatment-category.component.html',
  styleUrls: ['./treatment-category.component.scss']
})

export class TreatmentCategoryComponent implements OnInit, OnChanges{

  currentTreatmentCategory: TreatmentCategory = {
    categoryName: "-"
  }
  treatmentCategories: TreatmentCategory[] = [];

  constructor(public dialog: MatDialog, 
    private treatmentCategoryService: TreatmentCategoryService,
    private toastr: ToastrService) {}

  ngOnInit(): void {
    this.getTreatmentCategories();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getTreatmentCategories();
  }

  selectRow(treatmentCategory: TreatmentCategory): void {
    this.currentTreatmentCategory = treatmentCategory;
  }

  addCategory(): void {
    const dialogRef = this.dialog.open(TreatmentCategoryModalComponent);
    dialogRef.componentInstance.data = {
      treatmentCategoryToUpdate: {
        categoryName: "-"
      },
      isEdit: false
    }
    dialogRef.afterClosed().subscribe(result => {
      this.getTreatmentCategories();
    });
  }

  editCategory(treatmentCategory: TreatmentCategory): void {
    const dialogRef = this.dialog.open(TreatmentCategoryModalComponent);
    dialogRef.componentInstance.data = {
      treatmentCategoryToUpdate: treatmentCategory,
      isEdit: true
    }
    dialogRef.afterClosed().subscribe(result => {
      this.getTreatmentCategories();
    });
  }

  deleteCategory(treatmentCategory: TreatmentCategory): void {
    this.treatmentCategoryService.deleteTreatmentCategory(treatmentCategory.id ? treatmentCategory.id : 0).subscribe(treatmentCategory => {
      this.getTreatmentCategories();
    });
  }

  getTreatmentCategories() {
    this.treatmentCategoryService.getTreatmentCategories().subscribe(treatmentCategories => {
      this.treatmentCategories = treatmentCategories;
    })
  }
}
