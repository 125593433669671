import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { AppointmentComponent } from "./appointment/appointment.component";
import { HomeComponent } from "./home/home.component";
import { MedicComponent } from "./medic/medic.component";
import { PatientComponent } from "./patient/patient.component";
import { UserComponent } from './user.component';
import { ClinicComponent } from './clinic/clinic.component';
import { TreatmentComponent } from './treatment/treatment.component';
import { TreatmentCategoryComponent } from './treatment-category/treatment-category.component';
import { PatientMainComponent } from './patient-main/patient-main.component';
import { PatientTreatmentComponent } from './patient-treatment/patient-treatment.component';
import { PatientAppointmentComponent } from './patient-appointment/patient-appointment.component';
import { PatientContactComponent } from './patient-contact/patient-contact.component';
import { PatientNotesComponent } from './patient-notes/patient-notes.component';

const moduleRoutes: Routes = [
    {
        path: '',
        component: UserComponent,
        children: [
            { path: '', component: HomeComponent},
            { path: 'pacienti', component: PatientComponent},
            { path: 'pacienti/:id', component: PatientMainComponent, children: [
                { path: 'tratamente', component: PatientTreatmentComponent },
                { path: 'programari', component: PatientAppointmentComponent},
                { path: 'contact', component: PatientContactComponent},
                { path: 'notite', component: PatientNotesComponent }
            ]},
            { path: 'medici', component: MedicComponent},
            { path: 'programari', component: AppointmentComponent},
            { path: 'home', component: HomeComponent},
            { path: 'clinici', component: ClinicComponent},
            { path: 'tratamente', component: TreatmentComponent},
            { path: 'categorii-tratamente', component: TreatmentCategoryComponent}
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(moduleRoutes)],
    exports: [RouterModule]
})

export class UserRoutingModule {}