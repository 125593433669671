<div class="modal-container">
    <div class="left-menu">
        <div class="nav-section">
            <div class="nav-item">
                <p>Informatii programare</p>
            </div>
            <div class="nav-item">
                <p>Programari</p>
            </div>
            <div class="nav-item nav-item-last">
                <p>Date pacient</p>
            </div>
        </div>
        <div class="status-section">
            <div class="header">
                Status
            </div>
            <div class="status-selection">
                <div class="status-option">
                    <input class="checkbox" type="checkbox"
                    [(ngModel)]="currentApp.confirmed" 
                    [checked]="currentApp.confirmed === true"
                    id="confirmed">
                    <span>Pacientul a confirmat</span>
                </div>
                <div class="status-option">
                    <input class="checkbox" type="checkbox"
                    [(ngModel)]="currentApp.arrived" 
                    [checked]="currentApp.arrived === true"
                    id="arrived">
                    <span>Pacientul a sosit</span>
                </div>
                <div class="status-option">
                    <input class="checkbox" type="checkbox"
                    [(ngModel)]="currentApp.finalized" 
                    [checked]="currentApp.finalized === true"
                    id="finalized">
                    <span>Programare finalizata</span>
                </div>
            </div>
            <div class="log">
                Log
            </div>
        </div>
    </div>
    <div class="right-panel">
        <div class="form-title" id="form-title">
            <app-svg-icon class="svg-size" [path]='"../../assets/images/appointments/" + formTitleIcon + ".svg"'></app-svg-icon>
            <div class="title">{{formTitle}}</div>
        </div>
        <div class="appointment-form">
            <div class="fields-container">
                <div class="field">
                    <div class="label">Medic</div>
                    <select class="select label" id="doctorDropdown" 
                        [(ngModel)]="currentApp.doctor" name="selectedDoctor"
                        id="doctor">
                        <option [ngValue]="currentApp.doctor" disabled selected>
                            {{currentApp.doctor?.lastName}} {{currentApp.doctor?.firstName}}
                            <!-- Selecteaza medic -->
                        </option>
                        <option *ngFor="let medic of medics" [ngValue]="medic">
                            {{medic.lastName}} {{medic.firstName}}
                        </option>
                    </select>
                </div>
                <div class="field">
                    <div class="label">Clinica</div>
                    <select class="select label" id="clinicDropdown" [(ngModel)]="currentApp.clinic" name="selectedClinic"
                        id="clinic">
                        <option [ngValue]="currentApp.clinic" disabled selected>
                            {{currentApp.clinic?.name}}
                            <!-- Selecteaza clinica -->
                        </option>
                        <option *ngFor="let clinic of clinics" [ngValue]="clinic">
                            {{ clinic.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="fields-container">
                <div class="field">
                    <div class="label">Pacient</div>
                    <select class="select label" [(ngModel)]="currentApp.patient" name="selectedPatient"
                        id="patient" (change)="changePatientDetails()">
                        <option [ngValue]="currentApp.patient" disabled selected>
                            {{currentApp.patient?.lastName}} {{currentApp.patient?.firstName}}
                            <!-- - -->
                        </option>
                        <option *ngFor="let patient of patients" [ngValue]="patient">
                            {{patient.lastName}} {{patient.firstName}}
                        </option>
                    </select>
                </div>
                <div class="field">
                    <button class="add-patient-btn" (click)="addPatient()">
                        <app-svg-icon class="svg-size-btn" [path]="'../../assets/images/appointments/addthis.svg'"></app-svg-icon>
                    </button>
                </div>
            </div>
            <div class="fields-container">
                <div class="field date-field">
                    <div class="label">Data</div>
                    <mat-form-field class="date-picker">
                        <input matInput [matDatepicker]="picker" placeholder="{{currentApp.date}}" disabled
                        [(ngModel)]="currentApp.date" id="date">
                        <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="false"></mat-datepicker-toggle>
                        <mat-datepicker #picker [disabled]="false"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="field date-field">
                    <div class="label">Ora</div>
                    <select class="select label" id="hourDropdown" [(ngModel)]="currentApp.hour" name="selectedHour"
                        id="hour">
                        <option value="" disabled selected>-</option>
                        <option *ngFor="let hour of hours" [ngValue]="hour">
                            {{ hour }}
                        </option>
                    </select>
                </div>
                <div class="field date-field">
                    <div class="label">Durata</div>
                    <select class="select label" id="durationDropdown" [(ngModel)]="currentApp.duration" name="selectedDuration"
                        id="duration">
                        <option value="" disabled selected>60 min</option>
                        <option *ngFor="let time of durationOptions" [ngValue]="time">
                            {{ time }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="fields-container info-text-fields-containter">
                <div class="field">
                    <div class="label">Info</div>
                    <textarea rows="4" cols="50" class="info-input label" type="text" name="information"
                        [(ngModel)]="currentApp.information" id="information"
                        (input)="onInformationInput($event)"></textarea>
                </div>
                <div class="field">
                    <div class="label">Adauga eticheta</div>
                    <textarea rows="4" cols="50" class="info-input label" type="text" name="labels"></textarea>
                </div>
            </div>
            <div class="fields-container">
                <div class="inline-field">
                    <input class="checkbox" type="checkbox" id="blocked"
                    [(ngModel)]="currentApp.blocked" 
                    [checked]="currentApp.blocked === true">
                    <div class="label label-margin">Block</div>
                </div>
            </div>
            <div class="fields-container">
                <div class="field full-width-field">
                    <div class="label">Observatii</div>
                    <div class="voice-input-box">
                        <textarea [(ngModel)]="currentApp.observations" rows="4" cols="50" 
                        class="info-input label" type="text" name="observations" 
                        id="observations" (input)="onObservationsInput($event)">
                        </textarea>
                        <div>
                            <button 
                                [ngClass]="holdingBtn ? 'add-patient-btn holdingBtn' : 'add-patient-btn'"
                                (mousedown)="startSpeechRecognition(); onSpeechRecBtn()"
                                (mouseup)="stopSpeechRecognition()"
                                (touchstart)="startSpeechRecognition()"
                                (touchend)="stopSpeechRecognition()">
                                <app-svg-icon class="svg-size-btn" [path]="'../../assets/images/appointments/mic.svg'"></app-svg-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fields-container modal-actions">
                <div class="field action-btns-field">
                    <button class="action-btn save-btn" (click)="save()">
                        <app-svg-icon class="save-size" [path]="'../../assets/images/btns/save.svg'">
                        </app-svg-icon>
                        Save
                    </button>
                    <button class="action-btn cancel-btn" (click)="close()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
