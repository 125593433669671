<div class="patients-page">
  <div class="container left-container">
    <div class="patient-details-container">

      <div class="actions-details">
        <div class="spacer"></div>
        <button mat-flat-button [matMenuTriggerFor]="contact" class="action-btn" [disabled]="disablePatientSubMenu" [routerLink]="['/pacienti', currentPatient.id, 'contact']">Contact</button>
        <mat-menu #contact="matMenu">
          <button mat-menu-item>Notite</button>
          <button mat-menu-item>Corespondenta</button>
          <button mat-menu-item>Documente</button>
          <button mat-menu-item>Formulare</button>
        </mat-menu>
        <button mat-flat-button [matMenuTriggerFor]="appointments" class="action-btn" [disabled]="disablePatientSubMenu" [routerLink]="['/pacienti', currentPatient.id, 'programari']">Programari</button>
        <mat-menu #appointments="matMenu">
          <button mat-menu-item>Recalluri</button>
          <button mat-menu-item>Vizita urmatoare</button>
        </mat-menu>
        <button mat-flat-button [matMenuTriggerFor]="treatments" class="action-btn" [disabled]="disablePatientSubMenu" [routerLink]="['/pacienti', currentPatient.id, 'tratamente']">Tratamente</button>
        <mat-menu #treatments="matMenu">
          <button mat-menu-item>Plan de tratament</button>
          <button mat-menu-item>Istoric</button>
          <button mat-menu-item>Paro chart</button>
        </mat-menu>
        <button mat-flat-button [matMenuTriggerFor]="medicalData" class="action-btn" [disabled]="disablePatientSubMenu" [routerLink]="['/pacienti', currentPatient.id]">Date med.</button>
        <mat-menu #medicalData="matMenu">
          <button mat-menu-item>Anamneza</button>
          <button mat-menu-item>Alerte medicale</button>
          <button mat-menu-item>Radiografii</button>
          <button mat-menu-item>Retete</button>
        </mat-menu>
        <button mat-flat-button [matMenuTriggerFor]="finance" class="action-btn" [disabled]="disablePatientSubMenu" [routerLink]="['/pacienti', currentPatient.id]">Financiar</button>
        <mat-menu #finance="matMenu">
          <button mat-menu-item>Plati</button>
          <button mat-menu-item>Facturi si chitante</button>
          <button mat-menu-item>Credit</button>
          <button mat-menu-item>Abonamente</button>
        </mat-menu>
      </div>

      <div class="patient-details-section-top main-details">
        <div class="photo-placeholder">
            Photo Placeholder
        </div>
        <div class="column1">
          <div class="header">
            {{currentPatient.lastName}} {{currentPatient.firstName}}
          </div>
          <div class="sub-header">
            <span class="field">DOB </span><span>:{{currentPatient.lastName}}</span>
          </div>
          <div class="sub-header">
            <span class="field">ID </span><span>:{{currentPatient.id}}</span>
          </div>
          <div class="sub-header">
            <span class="field">Gen </span><span>:{{currentPatient.gender}}</span>
          </div>
          <div class="sub-header">
            <span class="field">CNP </span><span>:{{currentPatient.cnp}}</span>
          </div>
        </div>
        <div class="column1">
          <div class="header2">
            Contact Info
          </div>
          <div class="sub-header">
            <span class="field">Telefon </span><span>:{{currentPatient.phone}}</span>
          </div>
          <div class="sub-header">
            <span class="field">Email </span><span>:{{currentPatient.email}}</span>
          </div>
          <div class="sub-header">
            <span class="field">Adresa </span><span>:{{currentPatient.city}}, {{currentPatient.street}}</span>
          </div>
        </div>
        <div class="column1 column-end-right">
          <small class="d-block"></small>
          <button class="edit-btn" (click)="editPatient(this.currentPatient)">
            <app-svg-icon class="svg-size" [path]="'../../assets/images/btns/pencil.svg'">
            </app-svg-icon>
            <div>Edit</div>
          </button>
        </div>
      </div>
      <div class="actions-details">
        <div class="spacer"></div>
        <button class="action-btn" (click)="addAppointment()">
          <app-svg-icon class="plus-size" [path]="'../../assets/images/btns/plus.svg'">
          </app-svg-icon>
          Programare
        </button>
        <button class="action-btn" (click)="addNote()" [disabled]="disablePatientSubMenu">
          <app-svg-icon class="plus-size" [path]="'../../assets/images/btns/plus.svg'">
          </app-svg-icon>
          Notita
        </button>
      </div>
      <div class=" patient-details-section secondary-details">
        <div class="spacer"></div>
        <div class="column1">
          <div class="sub-header">
            <span class="field">Alergii </span><span>:-</span>
          </div>
          <div class="sub-header">
            <span class="field">Medicatie </span><span>:-</span>
          </div>
          <div class="sub-header">
            <span class="field">Afectiuni cardiace </span><span>:-</span>
          </div>
          <div class="sub-header">
            <span class="field">afectiuni circulatorii </span><span>:-</span>
          </div>
        </div>
        <div class="column1">
          <div class="sub-header">
            <span class="field">Clinica </span><span>: -</span>
          </div>
          <div class="sub-header">
            <span class="field">Medic </span><span>: -</span>
          </div>
          <div class="sub-header">
            <span class="field">Urmatoarea programare </span><span>-</span>
          </div>
        </div>
        <div class="column1">
          <div class="sub-header">
            <span class="field">Tratamente nefinalizate </span><span>: -</span>
          </div>
          <div class="sub-header">
            <span class="field">Tratamente recurente </span><span>: -</span>
          </div>
        </div>
      </div>

      <!-- <div class="patient-details-section payments-details">
        <div class="spacer"></div>
        <div class="column1">
          <div class="sub-header">
            <span class="field">Credit </span><span>: -</span>
          </div>
          <div class="sub-header">
            <span class="field">Rest de plata </span><span>: -</span>
          </div>
        </div>
        <div class="column1">
          <div class="sub-header">
            <span class="field">Abonament </span><span>: -</span>
          </div>
        </div>
      </div> -->
    </div>

    <button class="add-patient-btn" (click)="addPatient()">
      <div class="left-btn">
        +
      </div>
      <div class="right-btn">
        Pacient
      </div>
    </button>
    <div class="patient-list-container">
      <table class="table-responsive patients-table">
        <thead>
          <tr>
            <th scope="col">ID
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Nume
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Prenume
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">CNP
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Telefon
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Email
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let patient of patients" (click)="selectRow(patient)">
            <td>{{patient.id}}</td>
            <td>{{patient.lastName}}</td>
            <td>{{patient.firstName}}</td>
            <td>{{patient.cnp}}</td>
            <td>{{patient.phone}}</td>
            <td>{{patient.email}}</td>
            <td>
              <button class="edit-btn" (click)="editPatient(patient)">
                <app-svg-icon class="svg-size" [path]="'../../assets/images/btns/pencil.svg'">
                </app-svg-icon>
                <div>Edit</div>
              </button>
            </td>
            <td>
              <button class="delete-btn" (click)="deletePatient(patient)">
                <app-svg-icon class="svg-size" [path]="'../../assets/images/btns/trash.svg'">
                </app-svg-icon>
              <div>Delete</div>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <app-shortcuts-container class="container right-container">
  </app-shortcuts-container>
</div>