import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentModalComponent } from '../appointment-modal/appointment-modal.component';

@Component({
  selector: 'app-shortcuts-container',
  templateUrl: './shortcuts-container.component.html',
  styleUrls: ['./shortcuts-container.component.scss']
})
export class ShortcutsContainerComponent implements OnInit {

  shortList: string[] = ['Pricop Mihai', 'Mihoc Diana'];

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  addAppointment(selectedDate: Date) {
    const dialogRef = this.dialog.open(AppointmentModalComponent);
    dialogRef.componentInstance.data = {
      appointmentToUpdate: {
        doctor: null,
        clinic: null,
        patient: null,
        date: selectedDate,
        hour: "",
        duration: "60 min",
        information: "",
        blocked: false,
        observations: "",
        confirmed: false,
        arrived: false,
        finalized: false
      },
      isEdit: false
    }
  }
}
