import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.development';
import { Medic } from '../user/model/medic';
import { AuthService } from './auth.service';
import { UtilsService } from './utils.service';
import { EMPTY, Observable, catchError, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MedicService {

    headers: HttpHeaders = new HttpHeaders({"Content-Type":"application/json"});

    constructor(private http: HttpClient, private authService: AuthService,
        private utilsService: UtilsService
    ) {}

    // TODO: map medics correctly
    getMedics(): Observable<Medic[]> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.get<Medic[]>(environment.doctorUrl, {headers: this.headers})
            .pipe(
                map(medics => medics.map(
                    medic => (
                        {
                            id: medic.id,
                            lastName: medic.lastName,
                            firstName: medic.firstName,
                            phone: medic.phone,
                            email: medic.email,
                            specialty: medic.specialty,
                            gender: medic.gender,
                            country: medic.country,
                            county: medic.county,
                            city: medic.city,
                            street: medic.street,
                            birthdate: medic.birthdate
                        }
                    )
                )),
                catchError((err, caught) => {
                    if (err.status === 403 || err.status === 401) {
                        this.utilsService.sessionExpired();
                    }
                    console.log(err.status);
                    return EMPTY;
                })
            );
    }

    updateMedic(medic: Medic): Observable<Medic> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.put<Medic>(
            environment.doctorUrl + "/" + (medic.id ? medic.id : ''),
            medic, {headers: this.headers});
    }

    addMedic(medic: Medic): Observable<Medic> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.post<Medic>(environment.doctorUrl, medic, {headers: this.headers});
    }

    deleteMedic(id: number): Observable<number> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.delete<number>(
            environment.doctorUrl + "/" + (id ? id : 0), {headers: this.headers})
    }
}