<div class="teeth-info">
    <div class="teeth-map-container">
      <div class="teeth-list">
        <mat-grid-list cols="16" rowHeight="8rem">
          <mat-grid-tile *ngFor="let tooth of teethPhotos">
            <mat-card>
              <!-- <input type="radio" name="iconType"> -->
              <mat-card-title *ngIf="tooth.isHeader">
                <p>{{ tooth.id }}</p>
              </mat-card-title>
              <img mat-card-image [src]="tooth.url" alt="Photo">
              <mat-card-footer *ngIf="!tooth.isHeader">
                <p>{{ tooth.id }}</p>
              </mat-card-footer>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
    <div class="teeth-map-container">

    </div>
</div>
<button class="add-treatment-btn" (click)="addPatientTreatment()">
    <div class="left-btn">
      +
    </div>
    <div class="right-btn">
      Tratament
    </div>
</button>
<div class="patient-navigate-container">
    <table class="table-responsive patients-table">
        <thead>
          <tr>
            <th scope="col">Data
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Tratament
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Dinti
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Medic
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Atribut
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Observatii
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="" id="keywords">
                </div>
            </th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let treatment of patientTreatments">
            <td>{{treatment.date | date: 'dd.LL.yyyy'}}</td>
            <td>{{treatment.treatment?.id}} {{treatment.treatment?.treatmentName}}</td>
            <td>{{treatment.teeth.toString()}}</td>
            <!--TODO: properly set medic or user-->
            <td>Dr. {{treatment.doctor?.lastName}}</td>
            <td>{{treatment.atribut}}</td>
            <td>{{treatment.observations}}</td>
            <td>
              <button class="edit-btn" (click)="editPatientTreatment(treatment)">
                <app-svg-icon class="svg-size" [path]="'../../assets/images/btns/pencil.svg'">
                </app-svg-icon>
                <div>Edit</div>
              </button>
            </td>
            <td>
              <button class="delete-btn" (click)="deletePatientTreatment(treatment)">
                <app-svg-icon class="svg-size" [path]="'../../assets/images/btns/trash.svg'">
                </app-svg-icon>
              <div>Delete</div>
              </button>
            </td>
          </tr>
        </tbody>
    </table>
</div>
