import { Component, OnChanges, OnInit, SimpleChanges, Inject } from '@angular/core';
import { DOCUMENT, DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Appointment } from '../model/appointment';
import { AppointmentService } from '../../services/appointment.service';
import { AppointmentModalComponent } from '../appointment-modal/appointment-modal.component';
import { Patient } from '../model/patient';
import { PatientService } from '../../services/patient.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-patient-appointment',
  templateUrl: './patient-appointment.component.html',
  styleUrls: ['./patient-appointment.component.scss']
})
export class PatientAppointmentComponent implements OnInit, OnChanges{

  patientId: string = '';
  appointments: Appointment[] = [];

  currentPatient: Patient = {
    lastName: "-",
    firstName: "-",
    cnp: "-",
    phone: "-",
    email: "-",
    birthdate: null,
    gender: "-",
    country: "-",
    county: "-",
    city: "-",
    street: "-",
    ownerLastName: "-",
    ownerFirstName: "-",
    ownerPhone: "-",
    familyName: "-",
    familyMember: "-",
    language: "-",
    secondaryContact: "-",
    smsNotifications: false,
    emailNotifications: false,
    smsMarketing: false,
    emailMarketing: false,
    phoneMarketing: false,
    clinic: null,
    doctor: null
  }

  constructor(@Inject(DOCUMENT) document: Document,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private appointmentService: AppointmentService) {}

  ngOnInit(): void {
    this.getCurrentPatientId();
    this.getCurrentPatient();
    this.getAppointmentsForPatient();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getAppointmentsForPatient();
  }

  getCurrentPatientId(): void {
    this.route.parent?.params.subscribe(
      (params) => {
        this.patientId = params['id']
    });
  }

  getCurrentPatient(): void {
    this.patientService.getPatient(this.patientId).subscribe(
      patient => { this.currentPatient = patient}
    )
  }

  getAppointmentsForPatient() {
    this.appointmentService.getAppointmentsForPatient(this.patientId).subscribe(
      appointmnets => { this.appointments = appointmnets }
    )
  }

  addAppointment(): void {
    const dialogRef = this.dialog.open(AppointmentModalComponent);
    dialogRef.componentInstance.data = {
      appointmentToUpdate: {
        doctor: this.currentPatient.doctor,
        clinic: this.currentPatient.clinic,
        patient: this.currentPatient,
        date: null,
        hour: "-",
        duration: "60 min",
        information: "",
        blocked: false,
        observations: "",
        confirmed: false,
        arrived: false,
        finalized: false
      },
      isEdit: false
    }

    dialogRef.afterClosed().subscribe(result => {
      this.getAppointmentsForPatient();
    });
  }

  editAppointment(appointment: Appointment) {
    const dialogRef = this.dialog.open(AppointmentModalComponent);
    dialogRef.componentInstance.data = {
      appointmentToUpdate: appointment,
      isEdit: true
    }

    dialogRef.afterClosed().subscribe(result => {
      this.getAppointmentsForPatient();
    });
  }

  deleteAppointment(appointment: Appointment) {
    this.appointmentService.deleteAppointment(appointment.id ? appointment.id : 0).subscribe( result => { 
      this.getAppointmentsForPatient() 
    });
  }

  displayAppointmentStatus(isConfirmed: boolean): string {
    let status = 'Confirmat';
    if(!isConfirmed) {
      return 'Anulat';
    }
    return status;
  }
}
