import { Component, OnChanges, OnInit, SimpleChanges, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Patient } from '../model/patient';
import { PatientService } from '../../services/patient.service';
import { MatDialog } from '@angular/material/dialog';
import { PatientModalComponent } from '../patient-modal/patient-modal.component';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-patient-main',
  templateUrl: './patient-main.component.html',
  styleUrls: ['./patient-main.component.scss']
})
export class PatientMainComponent implements OnInit, OnChanges{

  currentPatient: Patient = {
    lastName: "-",
    firstName: "-",
    cnp: "-",
    phone: "-",
    email: "-",
    birthdate: null,
    gender: "-",
    country: "-",
    county: "-",
    city: "-",
    street: "-",
    ownerLastName: "-",
    ownerFirstName: "-",
    ownerPhone: "-",
    familyName: "-",
    familyMember: "-",
    language: "-",
    secondaryContact: "-",
    smsNotifications: false,
    emailNotifications: false,
    smsMarketing: false,
    emailMarketing: false,
    phoneMarketing: false,
    clinic: null,
    doctor: null
  }

  constructor(@Inject(DOCUMENT) document: Document,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute, 
    private patientService: PatientService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.getCurrentPatient();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.getPatients();
  }

  getCurrentPatient(): void {
    this.patientService.getPatient(this.route.snapshot.paramMap.get('id')).subscribe(
      patient => { this.currentPatient = patient}
    )
  }

  navigateTo(path: string) {
    this.router.navigate(['./' + path], {relativeTo: this.route});
  }

  editPatient(patient: Patient): void {
    const dialogRef = this.dialog.open(PatientModalComponent);
    dialogRef.componentInstance.data = {
      patientToUpdate: patient,
      isEdit: true
    }
  }
}
