<div class="clinics-page">
    <div class="container left-container">
        <div class="clinic-details-container">
            <div class="clinic-details-section-top">
                <div class="column1">
                    <div class="header">
                      {{currentClinic.name}}
                    </div>
                    <div class="sub-header">
                      <span class="field">ID </span><span>:{{currentClinic.id}}</span>
                    </div>
                    <div class="sub-header">
                      <span class="field">Numar cabinete </span><span>:{{currentClinic.cabinetsNo}}</span>
                    </div>
                  </div>
            </div>
        </div>
        <button class="add-clinic-btn" (click)="addClinic()">
            <div class="left-btn">
              +
            </div>
            <div class="right-btn">
              Clinica
            </div>
        </button>
        <div class="clinic-list-container">
            <table class="table-responsive clinics-table">
                <thead>
                  <tr>
                    <th scope="col">ID
                      <div class="input-group">
                        <input type="text" class="form-control" placeholder="" id="keywords">
                      </div>
                    </th>
                    <th scope="col">Nume
                      <div class="input-group">
                        <input type="text" class="form-control" placeholder="" id="keywords">
                      </div>
                    </th>
                    <th scope="col">Nr cabinete
                      <div class="input-group">
                        <input type="text" class="form-control" placeholder="" id="keywords">
                      </div>
                    </th>
                    <th scope="col">Tara
                      <div class="input-group">
                        <input type="text" class="form-control" placeholder="" id="keywords">
                      </div>
                    </th>
                    <th scope="col">Judet
                      <div class="input-group">
                        <input type="text" class="form-control" placeholder="" id="keywords">
                      </div>
                    </th>
                    <th scope="col">Oras
                      <div class="input-group">
                        <input type="text" class="form-control" placeholder="" id="keywords">
                      </div>
                    </th>
                    <th scope="col">Strada
                        <div class="input-group">
                          <input type="text" class="form-control" placeholder="" id="keywords">
                        </div>
                    </th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let clinic of clinics" (click)="selectRow(clinic)">
                    <td>{{clinic.id}}</td>
                    <td>{{clinic.name}}</td>
                    <td>{{clinic.cabinetsNo}}</td>
                    <td>{{clinic.country}}</td>
                    <td>{{clinic.county}}</td>
                    <td>{{clinic.city}}</td>
                    <td>{{clinic.street}}</td>
                    <td>
                      <button class="edit-btn" (click)="editClinic(clinic)">
                        <app-svg-icon class="svg-size" [path]="'../../assets/images/btns/pencil.svg'">
                        </app-svg-icon>
                        <div>Edit</div>
                      </button>
                    </td>
                    <td>
                      <button class="delete-btn" (click)="deleteClinic(clinic)">
                        <app-svg-icon class="svg-size" [path]="'../../assets/images/btns/trash.svg'">
                        </app-svg-icon>
                      <div>Delete</div>
                      </button>
                    </td>
                  </tr>
                </tbody>
            </table>
        </div>
    </div>
    <app-shortcuts-container class="container right-container">
    </app-shortcuts-container>
</div>
