import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.development';
import { Clinic } from '../user/model/clinic';
import { AuthService } from './auth.service';
import { UtilsService } from './utils.service';
import { EMPTY, Observable, catchError, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ClinicService {

    headers: HttpHeaders = new HttpHeaders({"Content-Type":"application/json"});

    constructor(private http: HttpClient, private authService: AuthService,
        private utilsService: UtilsService
    ) {}

    getClinics(): Observable<Clinic[]> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.get<Clinic[]>(environment.clinicUrl, {headers: this.headers})
            .pipe(
                map(clinics => clinics.map(
                    clinic => (
                        {
                            id: clinic.id,
                            name: clinic.name,
                            cabinetsNo: clinic.cabinetsNo,
                            country: clinic.country,
                            county: clinic.county,
                            city: clinic.city,
                            street: clinic.street,
                        }
                    )
                )),
                catchError((err, caught) => {
                    if (err.status === 403 || err.status === 401) {
                        this.utilsService.sessionExpired();
                    }
                    console.log(err.status);
                    return EMPTY;
                })
            );
    }

    updateClinic(clinic: Clinic): Observable<Clinic> {

        if (this.authService.getAuthToken() !== null) {
            // this.headers = this.headers = this.headers.set("Authorization", "Bearer " + this.authService.getAuthToken());
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.put<Clinic>(
            environment.clinicUrl + "/" + (clinic.id ? clinic.id : ''),
            clinic, {headers: this.headers});
    }

    addClinic(clinic: Clinic): Observable<Clinic> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.post<Clinic>(environment.clinicUrl, clinic, {headers: this.headers});
    }

    deleteClinic(id: number): Observable<number> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.delete<number>(
            environment.clinicUrl + "/" + (id ? id : 0), {headers: this.headers});
    }
}