<button class="action-btn" (click)="addNote()">
    <app-svg-icon class="plus-size" [path]="'../../assets/images/btns/plus.svg'">
    </app-svg-icon>
    Notita
</button>
<div class="patient-navigate-container">
    <table class="table-responsive patients-table">
        <thead>
          <tr>
            <th scope="col">Data
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">User
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Notita
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let note of notes">
            <td>{{note.date | date: 'dd.LL.yyyy'}}</td>
            <td>Dr. {{note.user}}</td>
            <td>{{note.note}}</td>
            <td>
              <button class="edit-btn" (click)="editNote(note)">
                <app-svg-icon class="svg-size" [path]="'../../assets/images/btns/pencil.svg'">
                </app-svg-icon>
                <div>Edit</div>
              </button>
            </td>
            <td>
              <button class="delete-btn" (click)="deleteNote(note)">
                <app-svg-icon class="svg-size" [path]="'../../assets/images/btns/trash.svg'">
                </app-svg-icon>
              <div>Delete</div>
              </button>
            </td>
          </tr>
        </tbody>
    </table>
</div>
