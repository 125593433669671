<div class="modal-container">
    <div class="form-title" id="form-title">
        <app-svg-icon class="svg-size-title" [path]='"../../assets/images/appointments/" + formTitleIcon + ".svg"'></app-svg-icon>
        <div class="title">{{formTitle}}</div>
    </div>
    <div class="info-container">
        <div class="photo-placeholder">
            Photo Placeholder
        </div>
        <div class="column1">
            <div class="header">
                {{data.medicToUpdate.lastName}} {{data.medicToUpdate.firstName}}
            </div>
            <div class="sub-header">
                <span class="field">DOB </span><span>:-</span>
            </div>
            <div class="sub-header">
                <span class="field">ID </span>
                <span>: {{data.medicToUpdate.id}}</span>
            </div>
            <div class="sub-header">
                <span class="field">Gen </span>
                <span>: {{data.medicToUpdate.gender}}</span>
            </div>
            <div class="sub-header">
                <span class="field">Specializare </span>
                <span>: {{data.medicToUpdate.specialty}}</span>
            </div>
        </div>
    </div>
    <div class="actions-details">
        <button class="action-btn">
            <app-svg-icon class="plus-size" [path]="'../../assets/images/btns/plus.svg'">
            </app-svg-icon>
            img
        </button>
    </div>
    <div class="header">Contact</div>
    <div class="contact-container">
        <div class="info-row" *ngFor="let item of inputItems">
            <div class="sub-header field">{{item.label}}</div>
            <div class="input-row">
                <div class="sub-header">:</div>
                <ng-container *ngIf="item.inputFields[0].isDatePicker; else selectField">
                    <mat-form-field class="date-picker">
                        <input matInput [matDatepicker]="picker" name={{item.inputFields[0].name}} placeholder={{item.inputFields[0].placeholder}} disabled
                            [(ngModel)]="item.inputFields[0].mapper" [id]=item.inputFields[0].name.toString()>
                        <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="false"></mat-datepicker-toggle>
                        <mat-datepicker #picker [disabled]="false"></mat-datepicker>
                    </mat-form-field>
                </ng-container>
                <ng-template #selectField>
                    
                    <select class="info-input" 
                        id="genderDropdown" [(ngModel)]="data.medicToUpdate.gender" name="selectedGender"
                        *ngIf="item.inputFields[0].isSelect; else inputField"
                        [id]=item.inputFields[0].name.toString()>
                        <option value="" disabled selected>-</option>
                        <option *ngFor="let gender of genders" [value]="gender">
                            {{ gender }}
                        </option>
                    </select>

                    <ng-template #inputField>
                        <input class="info-input" type="text" name={{inputItem.name}} 
                            placeholder={{inputItem.placeholder}} 
                            *ngFor="let inputItem of item.inputFields"
                            [(ngModel)]="inputItem.mapper"
                            [id]=inputItem.name.toString()>
                    </ng-template>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="modal-actions">
        <button class="save-btn" (click)="save()">
            <app-svg-icon class="save-size" [path]="'../../assets/images/btns/save.svg'">
            </app-svg-icon>
            Save
        </button>
        <button class="cancel-btn" (click)="close()">Cancel</button>
    </div>
</div>
