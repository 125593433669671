import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TreatmentModalComponent } from '../treatment-modal/treatment-modal.component';
import { Treatment } from '../model/treatment';
import { TreatmentService } from '../../services/treatment.service';
import { TreatmentCategory } from '../model/treatmentCategory';
import { TreatmentCategoryService } from '../../services/treatment.category.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-treatment',
  templateUrl: './treatment.component.html',
  styleUrls: ['./treatment.component.scss']
})
export class TreatmentComponent implements OnInit, OnChanges{

  currentTreatment: Treatment = {
    treatmentName: "-",
    treatmentCategory: null,
    price: 0,
    materialPrice: 0,
    pricePer: "",
    iconName: ""
  }

  treatments: Treatment[] = [];
  treatmentCategories: TreatmentCategory[] = [];
  selectedCategory: TreatmentCategory | null = null;

  constructor(public dialog: MatDialog, 
    private treatmentCategoryService: TreatmentCategoryService,
    private treatmentService: TreatmentService,
    private toastr: ToastrService) {}

  ngOnInit(): void {
    this.getTreatmentCategories();
    this.getTreatments();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getTreatments();
  }

  selectRow(treatment: Treatment): void {
    this.currentTreatment = treatment;
  }

  getTreatments(): void {
    this.treatmentService.getTreatments().subscribe(treatments => {
      this.treatments = treatments;
    })
  }

  getTreatmentCategories(): void {
    this.treatmentCategoryService.getTreatmentCategories().subscribe(treatmentCategories => {
      this.treatmentCategories = treatmentCategories;
    })
  }

  addTreatment(): void {
    const dialogRef = this.dialog.open(TreatmentModalComponent);
    dialogRef.componentInstance.data = {
      treatmentToUpdate: {
        treatmentName: "-",
        treatmentCategory: null,
        price: 0,
        materialPrice: 0,
        pricePer: "",
        iconName: ""
      },
      isEdit: false
    }
    dialogRef.afterClosed().subscribe(result => {
      this.getTreatments();
    });
  }

  editTreatment(treatment: Treatment): void {
    const dialogRef = this.dialog.open(TreatmentModalComponent);
    dialogRef.componentInstance.data = {
      treatmentToUpdate: treatment,
      isEdit: true
    }

    dialogRef.afterClosed().subscribe(result => {
      this.getTreatmentCategories();
    });
  }

  deleteTreatment(treatment: Treatment): void {
    this.treatmentService.deleteTreatment(treatment.id ? treatment.id : 0).subscribe(treatment => {
      this.getTreatments();
    });
  }
}
