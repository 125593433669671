<div class="container-theme">
    <div [@slideInOut]="menuState.toString()" class="left_menu" (mouseenter)="toggleMenu()" (mouseleave)="toggleMenu()">
        <div class="header">
            <div class="logo" id='logo' (click)="toggleMenu()">
                <a href="'/'">
                    <img src="/assets/images/menu/logo1.png"/>
                </a>
            </div>
            <div *ngIf="menuState" class="title">
                MedicCopilot
            </div>
            <div *ngIf="menuState" class="version">
                v1.0
            </div>
        </div>
        <div class="navigation">
            <nav>
                <ul>
                    <li>
                        <app-svg-icon *ngIf="menuState === false" class="menu_icon_collapsed" [path]="'assets/images/menu/home.svg'"></app-svg-icon>
                        <a *ngIf="menuState === true" [routerLink]="'/home'">
                            <app-svg-icon class="menu_icon" [path]="'assets/images/menu/home.svg'"></app-svg-icon>
                            Home
                        </a>
                    </li>
                    <li>
                        <app-svg-icon *ngIf="menuState === false" class="menu_icon_collapsed" [path]="'assets/images/menu/calendar.svg'"></app-svg-icon>
                        <a *ngIf="menuState === true" [routerLink]="'/programari'">
                            <app-svg-icon class="menu_icon" [path]="'assets/images/menu/calendar.svg'"></app-svg-icon>
                            Calendar
                        </a>
                    </li>
                    <li>
                        <app-svg-icon *ngIf="menuState === false" class="menu_icon_collapsed" [path]="'assets/images/menu/group.svg'"></app-svg-icon>
                        <a *ngIf="menuState === true" [routerLink]="'/pacienti'">
                            <app-svg-icon class="menu_icon" [path]="'assets/images/menu/group.svg'"></app-svg-icon>
                            Pacienti
                        </a>
                    </li>
                    <li>
                        <app-svg-icon *ngIf="menuState === false" class="menu_icon_collapsed" [path]="'assets/images/menu/chart_pie.svg'"></app-svg-icon>
                        <a *ngIf="menuState === true" [routerLink]="'/'">
                            <app-svg-icon class="menu_icon" [path]="'assets/images/menu/chart_pie.svg'"></app-svg-icon>
                            Rapoarte
                            <div class="arrow_right"></div>
                        </a>
                    </li>
                    <li (mouseenter)="toggleSubMenu()" (mouseleave)="toggleSubMenu()">
                        <app-svg-icon *ngIf="menuState === false" class="menu_icon_collapsed" [path]="'assets/images/menu/nomenclator.svg'"></app-svg-icon>
                        <a *ngIf="menuState === true" class="li-with-submenu" [routerLink]="'/'">
                            <app-svg-icon class="menu_icon" [path]="'assets/images/menu/nomenclator.svg'"></app-svg-icon>
                            Nomenclator
                            <div [ngClass]="subMenuState ? 'arrow_down': 'arrow_right'"></div>
                        </a>
                        <ul *ngIf="subMenuState === true" class="submenu">
                            <li>
                                <a class="submenu-anchor" [routerLink]="'/medici'">
                                    <div>Medici</div>
                                </a>
                            </li>
                            <li>
                                <a class="submenu-anchor" [routerLink]="'/tratamente'">
                                    <div>Tratamente</div>
                                </a>
                            </li>
                            <li>
                                <a class="submenu-anchor" [routerLink]="'/categorii-tratamente'">
                                    <div>Categorii tratamente</div>
                                </a>
                            </li>
                            <li>
                                <a class="submenu-anchor" [routerLink]="'/clinici'">
                                    <div>Clinici</div>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
        <div *ngIf="menuState === true" class="advertising">
            <div class="advertising_text">
                Upgrade to  PRO to get access to all Features!
            </div>
            <button class="get_pro_btn">
                Get Pro Now!
            </button>
        </div>
        <div class="help_settings">
            <nav>
                <ul>
                    <li>
                        <app-svg-icon *ngIf="menuState === false" class="menu_icon_collapsed" [path]="'assets/images/menu/comment_square.svg'"></app-svg-icon>
                        <a *ngIf="menuState === true" [routerLink]="'/'">
                            <app-svg-icon class="menu_icon" [path]="'assets/images/menu/comment_square.svg'"></app-svg-icon>
                            Ajutor
                            <div class="arrow_right"></div>
                        </a>
                    </li>
                    <li>
                        <app-svg-icon *ngIf="menuState === false" class="menu_icon_collapsed" [path]="'assets/images/menu/settings.svg'"></app-svg-icon>
                        <a *ngIf="menuState === true" [routerLink]="'/'">
                            <app-svg-icon class="menu_icon" [path]="'assets/images/menu/settings.svg'"></app-svg-icon>
                            Setari
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <div class="main">
        <div class="userbar">
            <div class="greeting_message">
                <!-- <div>Hello Last-Name 👋🏼 </div> -->
                <mat-toolbar class="search-block">
                    <mat-toolbar-row>
                        <button class="search-icon" mat-icon-button (click)="closeSearch()">
                            <mat-icon *ngIf="!isSearchOpen" class="search-icon">search</mat-icon>
                            <mat-icon *ngIf="isSearchOpen" class="search-icon">close</mat-icon>
                        </button>
                        <input class="search-control" type="text" placeholder="Search" [(ngModel)]="searchText" 
                            cdkOverlayOrigin #searchbar="cdkOverlayOrigin" (click)="showSearch()">
                        <!-- (blur)="closeSearch()"> -->
                    </mat-toolbar-row>
                </mat-toolbar>
                <div class="search-icon-container">
                    <app-svg-icon class="search-bar-icon" [path]="'assets/images/menu/group.svg'"></app-svg-icon>
                </div>
                <button class="mat-fab extended add-patient-btn" (click)="addPatient()">
                    <mat-icon [ngStyle]="{'color':'white', 'display': 'flex', 'align-items': 'center', 'height': '100%'}">add</mat-icon>
                    <div class="right-btn">Pacient</div>
                </button>
            </div>
            <div class="status_message">
                <div class="avatar"></div>
                <p>Dr. First-Name</p>
            </div>
        </div>
        <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="searchbar"
            [cdkConnectedOverlayOpen]="isSearchOpen">
            <mat-selection-list id="patientsList" class="show-list">
                <mat-list-item *ngFor="let p of patientsList | pfilter : searchText"
                    (click)="navigateToPatient(p)">
                    <mat-icon matListItemIcon>person</mat-icon>
                    <span >{{ p.lastName }} </span>
                    <span >{{ p.firstName }}</span>
                </mat-list-item>
            </mat-selection-list>
        </ng-template>
        <div class="content">
            <div>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>