<div class="modal-container">
    <div class="form-title" id="form-title">
        <app-svg-icon class="svg-size" [path]='"../../assets/images/appointments/addthis.svg"'></app-svg-icon>
        <div class="title">PACIENT NOU</div>
    </div>
    <div class="patient-form">
        <div class="label">Nume</div>
        <input class="input-field" type="text"
            [(ngModel)]="lastName" id="lastName">
        <div class="label">Prenume</div>
        <input class="input-field" type="text"
            [(ngModel)]="firstName" id="firstName">
        <div class="label">Telefon</div>
        <input class="input-field" type="text"
            [(ngModel)]="phone" id="phone">
        <div class="label">Email</div>
        <input class="input-field" type="text"
            [(ngModel)]="email" id="email">
        <div class="label">CNP</div>
        <input class="input-field" type="text"
            [(ngModel)]="cnp" id="cnp">
        <div class="label">Clinica</div>
        <select class="select label" id="clinicDropdown" [(ngModel)]="clinic" name="selectedClinic"
            id="clinic">
            <option [ngValue]="clinic" disabled selected>
                <!-- Selecteaza clinica -->
            </option>
            <option *ngFor="let clinic of clinics" [ngValue]="clinic">
                {{ clinic.name }}
            </option>
        </select>
        <div class="label">Observatii</div>
        <textarea rows="4" cols="50" class="info-input label" type="text" name="observations"
            [(ngModel)]="observations" id="observations"
            (input)="onObservationsInput($event)"></textarea>
    </div>
    <div class="field action-btns-field">
        <button class="action-btn save-btn" (click)="addPatient()">
            <app-svg-icon class="save-size" [path]="'../../assets/images/btns/save.svg'">
            </app-svg-icon>
            Save
        </button>
        <button class="action-btn cancel-btn" (click)="close()">Cancel</button>
    </div>
</div>
