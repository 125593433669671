<div class="modal-container">
    <div class="form-title" id="form-title">
        <app-svg-icon class="svg-size-title" [path]='"../../assets/images/appointments/" + formTitleIcon + ".svg"'></app-svg-icon>
        <div class="title">{{formTitle}}</div>
    </div>
    <div class="info-container">
        <div class="info-row">
            <div class="sub-header field">Denumire</div>
            <div class="input-row">
                <div class="sub-header">:</div>
                <input class="info-input" type="text" name="treatmentName" id="treatmentName" [(ngModel)]="currentTreatment.treatmentName">
            </div>
        </div>
        <div class="info-row">
            <div class="sub-header field">Categorie</div>
            <div class="input-row">
                <div class="sub-header">:</div>
                <select class="info-input" id="categoryDropdown" 
                    name="selectedCategory" [(ngModel)]="currentTreatment.treatmentCategory">
                    <option [ngValue]="currentTreatment.treatmentCategory" disabled selected>
                        {{currentTreatment.treatmentCategory?.categoryName}}
                    </option>
                    <option *ngFor="let category of treatmentCategories" [ngValue]="category">
                        {{ category.categoryName }}
                    </option>
                </select>
            </div>
        </div>
        <div class="info-row">
            <div class="sub-header field">Pret</div>
            <div class="input-row">
                <div class="sub-header">:</div>
                <input class="info-input" type="number" name="treatmentPrice" 
                    id="treatmentPrice" [(ngModel)]="currentTreatment.price">
            </div>
        </div>
        <div class="info-row">
            <div class="sub-header field">Pret material</div>
            <div class="input-row">
                <div class="sub-header">:</div>
                <input class="info-input" type="number" name="materialPrice" 
                    id="materialPrice" [(ngModel)]="currentTreatment.materialPrice">
            </div>
        </div>
        <div class="info-row last-row">
            <div class="sub-header field">Pretul se aplica pe</div>
            <div class="input-row-last">
                <div class="sub-header">:</div>
                <div class="per-price-option">
                    <mat-radio-group [(ngModel)]="currentTreatment.pricePer">
                        <mat-radio-button *ngFor="let option of pricePerOptions"
                            class="checkbox" color="primary" [value]="option">
                            {{option}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </div>
    <div class="icons-container">
        <div class="sub-header field">Selecteaza iconograma</div>
        <div class="photos-list">
            <mat-grid-list cols="5" rowHeight="10rem">
                <mat-grid-tile *ngFor="let photo of photos">
                  <mat-card (click)="selectPhoto(photo)" [id]="photo.name" [ngClass]="photo.selected ? 'highlighted' : ''">
                    <!-- <input type="radio" name="iconType"> -->
                    <img mat-card-image [src]="photo.url" alt="Photo">
                    <mat-card-footer>
                      <p>{{ photo.name }}</p>
                    </mat-card-footer>
                  </mat-card>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>
    <div class="modal-actions">
        <button class="save-btn" (click)="save()">
            <app-svg-icon class="save-size" [path]="'../../assets/images/btns/save.svg'">
            </app-svg-icon>
            Save
        </button>
        <button class="cancel-btn" (click)="close()">Cancel</button>
    </div>
</div>
