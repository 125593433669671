<div class="dashboard">
   <div class="statistics"></div>
   <div class="widgets">

      <div class="widtget_block appointments-preview">
         <div class="header">
            <p class="title">Programari</p>
            <p class="current_date">{{myDate | date: 'dd.LL.yyyy'}}</p>
         </div>

         <div class="calendar_block">
            <div class="days">
                  <div *ngFor="let day of week" [ngClass]="isHighlighted(day)">
                     <div class="dayNo">{{day.weekDayNo | date: 'd'}}</div>
                     <div class="dayName">{{day.weekDayName}}</div>
                  </div>
            </div>
            <div class="appointments-section">
               <div class="timeline">
                  <div class="time-marker" *ngFor="let hour of hoursTimeline">
                     {{hour}}
                  </div>
               </div>
               <!--
               TODO: - every appointment card needs to be set 
               accordingly based on hour
               -->
               <div class="appointment-card">
                  <div class="appointment-alerts">
                     <!--TODO: Reenable checkbox when fixing icons-->
                     <!-- <div class="checkbox">
                        <div class="has-arrived">
                           <div class="checkmark">
                              <div class="checkmark_stem"></div>
                              <div class="checkmark_kick"></div>
                           </div>
                        </div>
                        <app-svg-icon class="has-warning" [path]="'../assets/images/caution.svg'">
                        </app-svg-icon>
                     </div> -->
                     <div class="has-xray">
                        <p>RX</p>
                     </div>
                  </div>
                  <div class="pacient-name">
                     {{appointment.patient?.lastName}} {{appointment.patient?.firstName}}
                  </div>
                  <div class="appointment-description">{{appointment.observations}}</div>
               </div>
            </div>
         </div>
      </div>

      <div class="middle_widgets">
         <div class="widtget_block_small alerts">
            <div class="header">
               <p class="title">Alert</p>
            </div>
            <div class="alerts_block">

            </div>
         </div>
         <div class="widtget_block_small laboratory">
            <div class="header">
               <p class="title">Laborator</p>
            </div>
            <div class="laboratory_block">

            </div>
         </div>
      </div>
      <div class="widtget_block chat">
         <div class="header">
            <p class="title">Chat</p>
         </div>
         <div class="chat_block">

         </div>
      </div>
   </div>
</div>