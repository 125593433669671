import { Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ClinicService } from '../../services/clinic.service';
import { Clinic } from '../model/clinic';
import { ClinicModalComponent } from '../clinic-modal/clinic-modal.component';

@Component({
  selector: 'app-clinic',
  templateUrl: './clinic.component.html',
  styleUrls: ['./clinic.component.scss']
})
export class ClinicComponent implements OnInit, OnChanges {

  currentClinic: Clinic = {
    name: "-",
    cabinetsNo: 0,
    country: "-",
    county: "-",
    city: "-",
    street: "-"
  }
  clinics: Clinic[] = [];

  constructor(public dialog: MatDialog, 
    private clinicService: ClinicService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.getClinics();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getClinics();
  }

  selectRow(clinic:Clinic): void {
    this.currentClinic = clinic;
  }

  getClinics(): void {
    this.clinicService.getClinics().subscribe(clinics => {
      this.clinics = clinics;
    })
  }

  addClinic(): void {
    const dialogRef = this.dialog.open(ClinicModalComponent);
    dialogRef.componentInstance.data = {
      clinicToUpdate: {
        name: "-",
        cabinetsNo: 0,
        country: "-",
        county: "-",
        city: "-",
        street: "-"
      },
      isEdit: false
    }

    dialogRef.afterClosed().subscribe(result => {
      this.getClinics();
    });
  }

  editClinic(clinic:Clinic): void {
    const dialogRef = this.dialog.open(ClinicModalComponent);
    dialogRef.componentInstance.data = {
      clinicToUpdate: clinic,
      isEdit: true
    }

    dialogRef.afterClosed().subscribe(result => {
      this.getClinics();
    });
  }

  deleteClinic(clinic: Clinic): void {
    this.clinicService.deleteClinic(clinic.id ? clinic.id : 0).subscribe(clinic => {
      this.getClinics();
    });
  }
}
