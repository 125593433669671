<button class="action-btn" (click)="addAppointment()">
    <app-svg-icon class="plus-size" [path]="'../../assets/images/btns/plus.svg'">
    </app-svg-icon>
    Programare
</button>
<div class="patient-navigate-container">
    <table class="table-responsive patients-table">
        <thead>
          <tr>
            <th scope="col">Data
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Perioada
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Medic
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Clinica
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Status
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Etichete
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Observatii
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="" id="keywords">
                </div>
            </th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let appointment of appointments">
            <td>{{appointment.date | date: 'dd.LL.yyyy'}}</td>
            <td>{{appointment.hour}}</td>
            <td>Dr. {{appointment.doctor?.lastName}}</td>
            <td>{{appointment.clinic?.name}}</td>
            <!--TODO: create functions for each values to properly display data (mainly period of appointment)-->
            <td>{{displayAppointmentStatus(appointment.confirmed)}}</td>
            <td></td>
            <td>{{appointment.observations}}</td>
            <td>
              <button class="edit-btn" (click)="editAppointment(appointment)">
                <app-svg-icon class="svg-size" [path]="'../../assets/images/btns/pencil.svg'">
                </app-svg-icon>
                <div>Edit</div>
              </button>
            </td>
            <td>
              <button class="delete-btn" (click)="deleteAppointment(appointment)">
                <app-svg-icon class="svg-size" [path]="'../../assets/images/btns/trash.svg'">
                </app-svg-icon>
              <div>Delete</div>
              </button>
            </td>
          </tr>
        </tbody>
    </table>
</div>
