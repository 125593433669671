import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.development';
import { Treatment } from '../user/model/treatment';
import { AuthService } from './auth.service';
import { UtilsService } from './utils.service';
import { EMPTY, Observable, catchError, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TreatmentService {

    headers: HttpHeaders = new HttpHeaders({"Content-Type":"application/json"});

    constructor(private http: HttpClient, private authService: AuthService,
        private utilsService: UtilsService
    ) {}

    getTreatments(): Observable<Treatment[]> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.get<Treatment[]>(environment.treatmentUrl, {headers: this.headers})
            .pipe(
                map(treatments => treatments.map(
                    treatment => (
                        {
                            id: treatment.id,
                            treatmentName: treatment.treatmentName,
                            treatmentCategory: treatment.treatmentCategory,
                            price: treatment.price,
                            materialPrice: treatment.materialPrice,
                            pricePer: treatment.pricePer,
                            iconName: treatment.iconName
                        }
                    )
                )),
                catchError((err, caught) => {
                    if (err.status === 403 || err.status === 401) {
                        this.utilsService.sessionExpired();
                    }
                    console.log(err.status);
                    return EMPTY;
                })
            );
    }

    updateTreatment(treatment: Treatment): Observable<Treatment> {

        if (this.authService.getAuthToken() !== null) {
            // this.headers = this.headers = this.headers.set("Authorization", "Bearer " + this.authService.getAuthToken());
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.put<Treatment>(
            environment.treatmentUrl + "/" + (treatment.id ? treatment.id : ''),
            treatment, {headers: this.headers});
    }

    addTreatment(treatment: Treatment): Observable<Treatment> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.post<Treatment>(environment.treatmentUrl, treatment, {headers: this.headers});
    }

    deleteTreatment(id: number): Observable<number> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.delete<number>(
            environment.treatmentUrl + "/" + (id ? id : 0), {headers: this.headers});
    }
}