import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.development';
import { TreatmentCategory } from '../user/model/treatmentCategory';
import { AuthService } from './auth.service';
import { UtilsService } from './utils.service';
import { EMPTY, Observable, catchError, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TreatmentCategoryService {

    headers: HttpHeaders = new HttpHeaders({"Content-Type":"application/json"});

    constructor(private http: HttpClient, private authService: AuthService,
        private utilsService: UtilsService
    ) {}

    getTreatmentCategories(): Observable<TreatmentCategory[]> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.get<TreatmentCategory[]>(environment.treatmentCategoryUrl, {headers: this.headers})
            .pipe(
                map(treatmentCategories => treatmentCategories.map(
                    treatmentCategory => (
                        {
                            id: treatmentCategory.id,
                            categoryName: treatmentCategory.categoryName
                        }
                    )
                )),
                catchError((err, caught) => {
                    if (err.status === 403 || err.status === 401) {
                        this.utilsService.sessionExpired();
                    }
                    console.log(err.status);
                    return EMPTY;
                })
            );
    }

    updateTreatmentCategory(treatmentCategory: TreatmentCategory): Observable<TreatmentCategory> {

        if (this.authService.getAuthToken() !== null) {
            // this.headers = this.headers = this.headers.set("Authorization", "Bearer " + this.authService.getAuthToken());
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.put<TreatmentCategory>(
            environment.treatmentCategoryUrl + "/" + (treatmentCategory.id ? treatmentCategory.id : ''),
            treatmentCategory, {headers: this.headers});
    }

    addTreatmentCategory(treatmentCategory: TreatmentCategory): Observable<TreatmentCategory> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.post<TreatmentCategory>(environment.treatmentCategoryUrl, treatmentCategory, {headers: this.headers});
    }

    deleteTreatmentCategory(id: number): Observable<number> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.delete<number>(
            environment.treatmentCategoryUrl + "/" + (id ? id : 0), {headers: this.headers});
    }
}