import { Component, OnChanges, OnInit, SimpleChanges, Inject } from '@angular/core';
import { DOCUMENT, DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { PatientTreatmentModalComponent } from '../patient-treatment-modal/patient-treatment-modal.component';
import { Patient } from '../model/patient';
import { PatientTreatment } from '../model/patientTreatment';
import { PatientTeethMap } from '../model/patientTeethMap';
import { PatientService } from '../../services/patient.service';
import { PatientTeethMapService } from '../../services/patient.teeth.map.service';
import { PatientTreatmentService } from '../../services/patient.treatment.service';
import { ActivatedRoute } from '@angular/router';

export class ToothPhoto {
  id: string;
  url: string;
  isHeader: boolean;

  constructor(id: string, url: string, isHeader: boolean) {
    this.id = id;
    this.url = url;
    this.isHeader = isHeader;
  }
}

@Component({
  selector: 'app-patient-treatment',
  templateUrl: './patient-treatment.component.html',
  styleUrls: ['./patient-treatment.component.scss']
})
export class PatientTreatmentComponent implements OnInit, OnChanges{

  patientId: string = '';
  teethPhotos: ToothPhoto[] = [];

  currentPatient: Patient = {
    lastName: "-",
    firstName: "-",
    cnp: "-",
    phone: "-",
    email: "-",
    birthdate: null,
    gender: "-",
    country: "-",
    county: "-",
    city: "-",
    street: "-",
    ownerLastName: "-",
    ownerFirstName: "-",
    ownerPhone: "-",
    familyName: "-",
    familyMember: "-",
    language: "-",
    secondaryContact: "-",
    smsNotifications: false,
    emailNotifications: false,
    smsMarketing: false,
    emailMarketing: false,
    phoneMarketing: false,
    clinic: null,
    doctor: null
  }

  patientTeethMap: PatientTeethMap = {
    patient: this.currentPatient,
    tooth18: "",
    tooth17: "",
    tooth16: "",
    tooth15: "",
    tooth14: "",
    tooth13: "",
    tooth12: "",
    tooth11: "",
    tooth21: "",
    tooth22: "",
    tooth23: "",
    tooth24: "",
    tooth25: "",
    tooth26: "",
    tooth27: "",
    tooth28: "",
    tooth38: "",
    tooth37: "",
    tooth36: "",
    tooth35: "",
    tooth34: "",
    tooth33: "",
    tooth32: "",
    tooth31: "",
    tooth41: "",
    tooth42: "",
    tooth43: "",
    tooth44: "",
    tooth45: "",
    tooth46: "",
    tooth47: "",
    tooth48: ""
  };

  patientTreatments: PatientTreatment[] = []

  constructor(@Inject(DOCUMENT) document: Document,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private patientTeethMapService: PatientTeethMapService,
    private patientTreatmentService: PatientTreatmentService) {}

  ngOnInit(): void {
    this.getCurrentPatientId();
    this.getCurrentPatient();
    this.getPatientTeethMap();
    this.getTreatmentsForPatient();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getTreatmentsForPatient();    
  }

  getCurrentPatientId(): void {
    this.route.parent?.params.subscribe(
      (params) => {
        this.patientId = params['id']
    });
  }

  getCurrentPatient(): void {
    this.patientService.getPatient(this.patientId).subscribe(
      patient => { this.currentPatient = patient }
    )
  }

  getPatientTeethMap(): void {
    this.patientTeethMapService.getTeethMapForPatient(this.patientId).subscribe(
      teethMap => { 
        this.patientTeethMap = teethMap;
        this.fetchPhotos();
      }
    )
  }

  getTreatmentsForPatient(): void {
    this.patientTreatmentService.getTreatmentsForPatient(this.patientId).subscribe(
      treatments => { this.patientTreatments = treatments }
    )
  }

  addPatientTreatment(): void {
    const dialogRef = this.dialog.open(PatientTreatmentModalComponent);
    dialogRef.componentInstance.data = {
      patientTreatmentToUpdate: {
        treatment: null,
        date: null,
        teeth: [],
        doctor: null,
        toothArea: "",
        clinic: null,
        price: 0,
        percentDiscount: 0,
        negotiatedPrice: 0,
        status: "",
        recallNumber: 0,
        recallType: "",
        observations: "",
        atribut: "",
        patient: this.currentPatient
      }, 
      isEdit: false,
    }

    dialogRef.afterClosed().subscribe(result => {
      this.getTreatmentsForPatient();
      this.getPatientTeethMap();
      this.fetchPhotos();
    });
  }

  editPatientTreatment(patientTreatment: PatientTreatment): void {
    const dialogRef = this.dialog.open(PatientTreatmentModalComponent);
    dialogRef.componentInstance.data = {
      patientTreatmentToUpdate: patientTreatment,
      isEdit: true
    }

    dialogRef.afterClosed().subscribe(result => {
      this.getTreatmentsForPatient();
    });
  }

  deletePatientTreatment(patientTreatment: PatientTreatment): void {
    this.patientTreatmentService.deletePatientTreatment(patientTreatment.id ? patientTreatment.id : 0).subscribe( result => {
      this.getTreatmentsForPatient();
    })
  }

  // Teeth Map methods
  fetchPhotos(): void {
    this.teethPhotos = [];
    this.teethPhotos.push(new ToothPhoto("18", `../../../assets/images/teeth/${this.patientTeethMap.tooth18}.png`, true));
    this.teethPhotos.push(new ToothPhoto("17", `../../../assets/images/teeth/${this.patientTeethMap.tooth17}.png`, true));
    this.teethPhotos.push(new ToothPhoto("16", `../../../assets/images/teeth/${this.patientTeethMap.tooth16}.png`, true));
    this.teethPhotos.push(new ToothPhoto("15", `../../../assets/images/teeth/${this.patientTeethMap.tooth15}.png`, true));
    this.teethPhotos.push(new ToothPhoto("14", `../../../assets/images/teeth/${this.patientTeethMap.tooth14}.png`, true));
    this.teethPhotos.push(new ToothPhoto("13", `../../../assets/images/teeth/${this.patientTeethMap.tooth13}.png`, true));
    this.teethPhotos.push(new ToothPhoto("12", `../../../assets/images/teeth/${this.patientTeethMap.tooth12}.png`, true));
    this.teethPhotos.push(new ToothPhoto("11", `../../../assets/images/teeth/${this.patientTeethMap.tooth11}.png`, true));
    this.teethPhotos.push(new ToothPhoto("21", `../../../assets/images/teeth/${this.patientTeethMap.tooth21}.png`, true));
    this.teethPhotos.push(new ToothPhoto("22", `../../../assets/images/teeth/${this.patientTeethMap.tooth22}.png`, true));
    this.teethPhotos.push(new ToothPhoto("23", `../../../assets/images/teeth/${this.patientTeethMap.tooth23}.png`, true));
    this.teethPhotos.push(new ToothPhoto("24", `../../../assets/images/teeth/${this.patientTeethMap.tooth24}.png`, true));
    this.teethPhotos.push(new ToothPhoto("25", `../../../assets/images/teeth/${this.patientTeethMap.tooth25}.png`, true));
    this.teethPhotos.push(new ToothPhoto("26", `../../../assets/images/teeth/${this.patientTeethMap.tooth26}.png`, true));
    this.teethPhotos.push(new ToothPhoto("27", `../../../assets/images/teeth/${this.patientTeethMap.tooth27}.png`, true));
    this.teethPhotos.push(new ToothPhoto("28", `../../../assets/images/teeth/${this.patientTeethMap.tooth28}.png`, true));
    this.teethPhotos.push(new ToothPhoto("48", `../../../assets/images/teeth/${this.patientTeethMap.tooth48}.png`, false));
    this.teethPhotos.push(new ToothPhoto("47", `../../../assets/images/teeth/${this.patientTeethMap.tooth47}.png`, false));
    this.teethPhotos.push(new ToothPhoto("46", `../../../assets/images/teeth/${this.patientTeethMap.tooth46}.png`, false));
    this.teethPhotos.push(new ToothPhoto("45", `../../../assets/images/teeth/${this.patientTeethMap.tooth45}.png`, false));
    this.teethPhotos.push(new ToothPhoto("44", `../../../assets/images/teeth/${this.patientTeethMap.tooth44}.png`, false));
    this.teethPhotos.push(new ToothPhoto("43", `../../../assets/images/teeth/${this.patientTeethMap.tooth43}.png`, false));
    this.teethPhotos.push(new ToothPhoto("42", `../../../assets/images/teeth/${this.patientTeethMap.tooth42}.png`, false));
    this.teethPhotos.push(new ToothPhoto("41", `../../../assets/images/teeth/${this.patientTeethMap.tooth41}.png`, false));
    this.teethPhotos.push(new ToothPhoto("31", `../../../assets/images/teeth/${this.patientTeethMap.tooth31}.png`, false));
    this.teethPhotos.push(new ToothPhoto("32", `../../../assets/images/teeth/${this.patientTeethMap.tooth32}.png`, false));
    this.teethPhotos.push(new ToothPhoto("33", `../../../assets/images/teeth/${this.patientTeethMap.tooth33}.png`, false));
    this.teethPhotos.push(new ToothPhoto("34", `../../../assets/images/teeth/${this.patientTeethMap.tooth34}.png`, false));
    this.teethPhotos.push(new ToothPhoto("35", `../../../assets/images/teeth/${this.patientTeethMap.tooth35}.png`, false));
    this.teethPhotos.push(new ToothPhoto("36", `../../../assets/images/teeth/${this.patientTeethMap.tooth36}.png`, false));
    this.teethPhotos.push(new ToothPhoto("37", `../../../assets/images/teeth/${this.patientTeethMap.tooth37}.png`, false));
    this.teethPhotos.push(new ToothPhoto("38", `../../../assets/images/teeth/${this.patientTeethMap.tooth38}.png`, false));
  }
}
