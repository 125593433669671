import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.development';
import { CredentialsDto } from '../_models/credentialsDto';
import { UserDto } from '../_models/userDto';
import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class AuthService {
    headers: HttpHeaders = new HttpHeaders({'Content-Type':'application/json'});

    constructor(private http: HttpClient) { }

    getAuthToken(): string | null {
        return window.localStorage.getItem("auth_token");
    }

    setAuthToken(token: string | null): void {
        if (token !== null) {
            window.localStorage.setItem("auth_token", token);
        } else {
            window.localStorage.removeItem("auth_token");
        }
    }

    setUserDetails(userDto: UserDto): void {
        window.localStorage.setItem("firstName", userDto.firstName);
        window.localStorage.setItem("lastName", userDto.lastName);
        window.localStorage.setItem("email", userDto.email);
        window.localStorage.setItem("role", userDto.role);
    }

    loginUser(user: CredentialsDto): Observable<UserDto> {
        return this.http.post<UserDto>(environment.loginUrl, user, {headers: this.headers});
    }

    registerUser(user: UserDto): Observable<UserDto> {
        return this.http.post<UserDto>(environment.registerUrl, user, {headers: this.headers});
    }
}