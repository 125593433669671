import { Component } from '@angular/core';

@Component({
  selector: 'app-patient-contact',
  templateUrl: './patient-contact.component.html',
  styleUrls: ['./patient-contact.component.scss']
})
export class PatientContactComponent {

}
