import { Component, Input, OnInit, HostListener  } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title:String = 'MedicCopilot';

  constructor (private router: Router) {
  }

  ngOnInit(): void {}
}
