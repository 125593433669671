<div class="content">
    <!-- <mat-card class="calendar"> -->
    <mat-calendar class="calendar" (selectedChange)="addAppointment($event)"></mat-calendar>
    <!-- </mat-card> -->
    <div class="section label"> 
        Lista scurta
    </div>
    <div class="section short-list-container">
        <mat-list role="list" class="short-list">
            <mat-list-item role="listitem" *ngFor="let item of shortList" class="short-list-item">
                <div class="item">
                    <div class="short-list-subitem">orto</div>
                    <div class="short-list-subitem-2">{{item}}</div>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
    <button mat-flat-button class="section label find-spot-btn">Find spot</button>
    <div class="section label"> 
        Sala de asteptare
    </div>
    <div class="section short-list-container">
        <mat-list role="list" class="short-list">
            <mat-list-item role="listitem" *ngFor="let item of shortList" class="short-list-item">
                <div class="item">
                    <div class="short-list-subitem">4min</div>
                    <div class="short-list-subitem-2">{{item}}</div>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</div>