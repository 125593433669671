<div class="modal-container">
    <div class="form-title" id="form-title">
        <app-svg-icon class="svg-size-title" [path]='"../../assets/images/appointments/" + formTitleIcon + ".svg"'></app-svg-icon>
        <div class="title">{{formTitle}}</div>
    </div>
    <div class="split-container">
        <div class="teeth-map-container">
            <mat-grid-list cols="16" rowHeight="8rem">
                <mat-grid-tile *ngFor="let tooth of teethPhotos">
                  <mat-card (click)="selectTeeth(tooth.id)" [id]="tooth.id">
                    <!-- <input type="radio" name="iconType"> -->
                    <mat-card-title *ngIf="tooth.isHeader">
                      <p>{{ tooth.id }}</p>
                    </mat-card-title>
                    <img mat-card-image [src]="tooth.url" alt="Photo">
                    <mat-card-footer *ngIf="!tooth.isHeader">
                      <p>{{ tooth.id }}</p>
                    </mat-card-footer>
                  </mat-card>
                </mat-grid-tile>
            </mat-grid-list>
            <div class="teeth-action-btns">
                <button mat-stroked-button color="primary" (click)="selectAllTeeth()">Selecteaza toti dintii</button>
                <button mat-stroked-button color="warn" (click)="deselectAllTeeth()">Deselecteaza toti dintii</button>
            </div>
        </div>
        <div class="info-container">
            <div class="info-row">
                <div class="sub-header field">Tratament</div>
                <div class="input-row">
                    <div class="sub-header">:</div>
                    <select class="info-input" id="treatmentDropdown" 
                        name="selectedTreatment" [(ngModel)]="currentPatientTreatment.treatment" (change)="onPriceChange()">
                        <option [ngValue]="currentPatientTreatment.treatment" disabled selected>
                            {{currentPatientTreatment.treatment?.treatmentName}}
                        </option>
                        <option *ngFor="let treatment of treatments" [ngValue]="treatment">
                            {{treatment.treatmentName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="info-row">
                <div class="sub-header field">Dinti</div>
                <div class="input-row">
                    <div class="sub-header">:</div>
                    <input class="info-input" type="text" name="teeth" id="teeth" placeholder="{{this.currentPatientTreatment.teeth.toString()}}" disabled>
                </div>
            </div>
            <div class="info-row">
                <div class="sub-header field">Suprafata</div>
                <div class="input-row">
                    <div class="sub-header">:</div>
                    <select class="info-input" id="toothAreaDropdown" 
                        name="selectedToothArea" [(ngModel)]="currentPatientTreatment.toothArea">
                        <option [ngValue]="currentPatientTreatment.toothArea" disabled selected>
                            {{currentPatientTreatment.toothArea}}
                        </option>
                        <option *ngFor="let area of toothAreaOptions" [ngValue]="area">
                            {{area}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="info-row">
                <div class="sub-header field">Medic</div>
                <div class="input-row">
                    <div class="sub-header">:</div>
                    <select class="info-input" id="medicDropdown" 
                        name="selectedMedic" [(ngModel)]="currentPatientTreatment.doctor">
                        <option [ngValue]="currentPatientTreatment.doctor" disabled selected>
                            {{currentPatientTreatment.doctor?.lastName}} {{currentPatientTreatment.doctor?.firstName}}
                        </option>
                        <option *ngFor="let medic of medics" [ngValue]="medic">
                            {{medic.lastName}} {{medic.firstName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="info-row">
                <div class="sub-header field">Clinica</div>
                <div class="input-row">
                    <div class="sub-header">:</div>
                    <select class="info-input" id="clinicDropdown" 
                        name="selectedClinic" [(ngModel)]="currentPatientTreatment.clinic">
                        <option [ngValue]="currentPatientTreatment.clinic" disabled selected>
                            {{currentPatientTreatment.clinic?.name}}
                        </option>
                        <option *ngFor="let clinic of clinics" [ngValue]="clinic">
                            {{clinic.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="info-row">
                <div class="sub-header field">Data</div>
                <div class="input-row">
                    <div class="sub-header">:</div>
                    <input class="info-input" type="text" name="date" id="date" placeholder="{{this.currentPatientTreatment.date| date: 'dd.LL.yyyy'}}" disabled>
                </div>
            </div>
            <div class="info-row">
                <div class="sub-header field">Pret material</div>
                <div class="input-row">
                    <div class="sub-header">:</div>
                    <input class="info-input" type="number" name="materialPrice" id="materialPrice" placeholder="{{this.currentPatientTreatment.treatment?.materialPrice}}" disabled>
                </div>
            </div>
            <div class="info-row">
                <div class="sub-header field">Pret</div>
                <div class="input-row">
                    <div class="sub-header">:</div>
                    <input class="info-input" type="number" name="price" id="price" placeholder="{{this.currentPatientTreatment.price}}" disabled>
                </div>
            </div>
            <div class="info-row">
                <div class="sub-header field">Procent reducere</div>
                <div class="input-row">
                    <div class="sub-header">:</div>
                    <input class="info-input" type="number" name="discountPercent" id="discountPercent" [(ngModel)]="currentPatientTreatment.percentDiscount" (input)="applyPercentageDiscount()">
                </div>
            </div>
            <div class="info-row">
                <div class="sub-header field">Pret negociat</div>
                <div class="input-row">
                    <div class="sub-header">:</div>
                    <input class="info-input" type="number" name="negociatedPrice" id="negociatedPrice" [(ngModel)]="currentPatientTreatment.negotiatedPrice" (input)="getPercentageFromPrice()">
                </div>
            </div>
            <div class="info-row">
                <div class="sub-header field">Status</div>
                <div class="input-row">
                    <div class="sub-header">:</div>
                    <select class="info-input" id="statusDropdown" 
                        name="selectedStatus" [(ngModel)]="currentPatientTreatment.status">
                        <option [ngValue]="currentPatientTreatment.status" disabled selected>
                            {{currentPatientTreatment.status}}
                        </option>
                        <option *ngFor="let status of statusOptions" [ngValue]="status">
                            {{status}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="info-row">
                <div class="sub-header field">Recall</div>
                <div class="input-row">
                    <div class="sub-header">:</div>
                    <input class="info-input" type="number" name="recallNo" id="recallNo" [(ngModel)]="currentPatientTreatment.recallNumber">
                </div>
            </div>
            <div class="info-row">
                <div class="sub-header field">Recall luni/saptamani</div>
                <div class="input-row">
                    <div class="sub-header">:</div>
                    <select class="info-input" id="recallTypeDropdown" 
                        name="selectedRecallType" [(ngModel)]="currentPatientTreatment.recallType">
                        <option [ngValue]="currentPatientTreatment.recallType" disabled selected>
                            {{currentPatientTreatment.recallType}}
                        </option>
                        <option *ngFor="let recallType of recallOptions" [ngValue]="recallType">
                            {{recallType}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="info-row">
                <div class="sub-header field">Observatii</div>
                <div class="input-row">
                    <div class="sub-header">:</div>
                    <input class="info-input" type="text" name="observations" id="observations"  [(ngModel)]="currentPatientTreatment.observations">
                </div>
            </div>
        </div>
    </div>
    
    <div class="modal-actions">
        <button class="save-btn" (click)="save()">
            <app-svg-icon class="save-size" [path]="'../../assets/images/btns/save.svg'">
            </app-svg-icon>
            Save
        </button>
        <button class="cancel-btn" (click)="close()">Cancel</button>
    </div>
</div>
