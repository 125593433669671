import { Component, Input, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { InputItem } from '../../_interfaces/InputItem';
import { Clinic } from '../model/clinic';
import { ClinicService } from '../../services/clinic.service';
import { ToastrService } from 'ngx-toastr'; 

@Component({
  selector: 'app-clinic-modal',
  templateUrl: './clinic-modal.component.html',
  styleUrls: ['./clinic-modal.component.scss']
})
export class ClinicModalComponent implements OnInit {

  @Input() data: {
    clinicToUpdate: Clinic,
    // command: String,
    isEdit: boolean
  }

  // title
  formTitle: string = "ADAUGA CLINICA";
  formTitleIcon: String = "addthis";

  inputItems: InputItem[] = [];

  constructor(
    @Inject(DOCUMENT) document: Document,
    public dialogRef: MatDialogRef<ClinicModalComponent>,
    private clinicService: ClinicService,
    private toastr: ToastrService) {
      this.data = {
        clinicToUpdate: {
          name: "-",
          cabinetsNo: 0,
          country: "-",
          county: "-",
          city: "-",
          street: "-"
        },
        isEdit: false
      }
  }

  ngOnInit(): void {
    if (this.data.isEdit) {
      document.getElementById('form-title')?.classList.add('form-title-edit');
      this.formTitle = "EDITEAZA CLINICA";
      this.formTitleIcon = "pencil";
    }

    this.inputItems = [
      {
        label: 'Nume*',
        inputFields: [
          {
            placeholder: "Nume",
            name: "name",
            mapper: this.data.clinicToUpdate.name
          }
        ]
      },
      {
        label: 'Numar cabinete',
        inputFields: [
          {
            placeholder: "",
            name: "cabinetsNo",
            mapper: this.data.clinicToUpdate.cabinetsNo
          }
        ]
      },
      {
        label: 'Adresa',
        inputFields: [
          {
            placeholder: "Tara",
            name: "country",
            mapper: this.data.clinicToUpdate.country
          },
          {
            placeholder: "Judet",
            name: "county",
            mapper: this.data.clinicToUpdate.county
          },
          {
            placeholder: "Localitate",
            name: "city",
            mapper: this.data.clinicToUpdate.city
          },
          {
            placeholder: "Strada",
            name: "street",
            mapper: this.data.clinicToUpdate.street
          }
        ]
      }
    ];
  }

  addClinic(): void {
    if (this.setClinicValues()) {
      this.clinicService.addClinic(this.data.clinicToUpdate).subscribe(response => {
        // this.toastr.success('Medic added successfully');
      }, error => {
        // this.toastr.error('Failed to add medic');
      })
      this.dialogRef.close();
    }
  }

  updateClinic(): void {
    if (this.setClinicValues()) {
      this.clinicService.updateClinic(this.data.clinicToUpdate).subscribe(response => {
        // this.toastr.success('Medic updated successfully');
      }, error => {
        // this.toastr.error('Failed to update medic');
      })
      this.dialogRef.close();
    }
  }

  setClinicValues(): boolean {
    this.data.clinicToUpdate.name = this.inputItems[0].inputFields[0].mapper;
    this.data.clinicToUpdate.cabinetsNo = Number(this.inputItems[1].inputFields[0].mapper);
    this.data.clinicToUpdate.country = this.inputItems[2].inputFields[0].mapper;
    this.data.clinicToUpdate.county = this.inputItems[2].inputFields[1].mapper;
    this.data.clinicToUpdate.city = this.inputItems[2].inputFields[2].mapper;
    this.data.clinicToUpdate.street = this.inputItems[2].inputFields[3].mapper;

    // VALIDATE INPUT FIELDS
    if (this.data.clinicToUpdate.name === '-' || 
      this.data.clinicToUpdate.name === '' ||
      this.data.clinicToUpdate.name === undefined) {
        document.getElementById('name')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('name')?.classList.remove('invalid-field');
    if (this.data.clinicToUpdate.cabinetsNo === 0 ) {
        document.getElementById('cabinetsNo')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('cabinetsNo')?.classList.remove('invalid-field');

    return true;
  }


  save(): void {
    if (this.data.isEdit) {
      this.updateClinic();
    } else {
      this.addClinic();
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
