<div class="modal-container">
    <div class="form-title title">
        NOTITA
    </div>
    <div class="input-container margin-top">
        <div class="input-column">
            <div class="label">
                Data
            </div>
            <input type="text" class="form-control label" 
                placeholder="{{this.currentNote.date| date: 'dd.LL.yyyy'}}"
                id="keywords" disabled>
        </div>
        <div class="input-column">
            <div class="label">
                Ora
            </div>
            <input type="text" class="form-control label" 
                placeholder="{{this.currentNote.date| date: 'hh:mm'}}"
                id="keywords" disabled>
        </div>
        <div class="input-column">
            <div class="label">
                User
            </div>
            <input type="text" class="form-control label" placeholder='Dr. {{this.currentNote.user}}' id="keywords" disabled>
        </div>
    </div>
    <div class="label sub-header margin-top">
        Notita
    </div>
    <div class="voice-input-box">
        <textarea [(ngModel)]="this.currentNote.note" rows="4" cols="50" class="info-input label" #observationsBox type="text" name="observations"></textarea>
        <div>
            <button 
                [ngClass]="holdingBtn ? 'add-patient-btn holdingBtn' : 'add-patient-btn'"
                (mousedown)="startSpeechRecognition(); onSpeechRecBtn()"
                (mouseup)="stopSpeechRecognition()"
                (touchstart)="startSpeechRecognition()"
                (touchend)="stopSpeechRecognition()">
                <app-svg-icon class="svg-size-btn" [path]="'../../assets/images/appointments/mic.svg'"></app-svg-icon>
            </button>
        </div>
    </div>
    <div class="input-container action-btns-field">
        <button class="action-btn save-btn" (click)="save()">
            <app-svg-icon class="save-size" [path]="'../../assets/images/btns/save.svg'">
            </app-svg-icon>
            Save
        </button>
        <button class="action-btn cancel-btn" (click)="close()">Cancel</button>
    </div>
</div>
