<div class="treatments-category-page">
    <div class="container left-container">
        <div class="action-btns">
            <button class="add-category-btn" (click)="addCategory()">
                <div class="left-btn">
                  +
                </div>
                <div class="right-btn">
                  Adauga categorie tratament
                </div>
            </button>
        </div>
        <div class="treatments-list-container">
            <table class="table-responsive treatments-table">
              <thead>
                <tr>
                  <th scope="col">ID
                    <div class="input-group">
                      <input type="text" class="form-control" placeholder="" id="keywords">
                    </div>
                  </th>
                  <th scope="col">Denumire
                    <div class="input-group">
                      <input type="text" class="form-control" placeholder="" id="keywords">
                    </div>
                  </th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let category of treatmentCategories" (click)="selectRow(category)">
                  <td>{{category.id}}</td>
                  <td>{{category.categoryName}}</td>
                  <td>
                    <button class="edit-btn" (click)="editCategory(category)">
                      <app-svg-icon class="svg-size-table" [path]="'../../assets/images/btns/pencil.svg'">
                      </app-svg-icon>
                      <div>Edit</div>
                    </button>
                  </td>
                  <td>
                    <button class="delete-btn" (click)="deleteCategory(category)">
                      <app-svg-icon class="svg-size-table" [path]="'../../assets/images/btns/trash.svg'">
                      </app-svg-icon>
                    <div>Delete</div>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
    </div>
    <app-shortcuts-container class="container right-container">
    </app-shortcuts-container>
</div>