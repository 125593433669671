<div class="appointments-page">
    <div class="container left-container">
        <div class="filter-section">
            <div class="filter-tag">
                <app-svg-icon class="svg-size" [path]="'../../assets/images/appointments/filter.svg'">
                </app-svg-icon>
                <div> Filtre:</div>
            </div>
            <div class="dropdown">
                <select class="select" id="doctorDropdown" [(ngModel)]="selectedDoctor" name="selectedDoctor">
                    <option value=null disabled selected>Medici</option>
                    <option *ngFor="let medic of medics" [ngValue]="medic">
                        {{medic.lastName}} {{medic.firstName}} 
                    </option>
                </select>
            </div>
            <div class="dropdown">
                <select class="select" id="clinicDropdown" [(ngModel)]="selectedClinic" name="selectedClinic">
                    <option value=null disabled selected>Clinici</option>
                    <option *ngFor="let clinic of clinics" [ngValue]="clinic">
                        {{ clinic.name }}
                    </option>
                </select>
            </div>
            <div class="dropdown calendar-view-action">
                <button class="action-btn left-btn" 
                [class.isHighlighted]="showDoctorCalendar" 
                (click)="toogleMedicView()">Medic</button>
                <button class="action-btn right-btn" 
                [class.isHighlighted]="showClinicCalendar" 
                (click)="toogleClinicView()">Cabinet</button>
            </div>
        </div>
        <div class="date-section">
            <button class="today" (click)="getCurrentDate()">
                Today
            </button>
            <div class="select-date">
                <button (click)="removeDay()">
                    <app-svg-icon class="arrow-svg" [path]="'../../assets/images/appointments/previous.svg'">
                    </app-svg-icon>
                </button>

                <div *ngIf="this.dayRange1; else multiple_days">
                    {{ currentDate | date: 'dd.LL.yyyy'}}
                </div>
                <ng-template #multiple_days>
                    <div>{{ leftDateRange | date: 'dd.LL'}} - {{ rightDateRange | date: 'dd.LL.yyyy' }}</div>
                </ng-template>

                <button (click)="addDay()">
                    <app-svg-icon class="arrow-svg" [path]="'../../assets/images/appointments/next.svg'">
                    </app-svg-icon>
                </button>
            </div>
            <div class="select-days-range">
                <button class="day-btn day-1-btn" 
                [class.isHighlightedDay]="dayRange1"
                (click)="toogleDayRange1View()">
                    1 zi
                </button>
                <button class="day-btn day-3-btn" 
                [class.isHighlightedDay]="dayRange3"
                (click)="toogleDayRange3View()">
                    3 zile
                </button>
                <button class="day-btn day-5-btn" 
                [class.isHighlightedDay]="dayRange5"
                (click)="toogleDayRange5View()">
                    5 zile
                </button>
                <button class="day-btn day-7-btn" 
                [class.isHighlightedDay]="dayRange7"
                (click)="toogleDayRange7View()">
                    7 zile
                </button>
            </div>
        </div>
        <div class="calendar-section">
            <div class="time-column">
                <div class="table-cell-header top-left-header">
                    <app-svg-icon class="arrow-svg svg-clock" [path]="'../../assets/images/appointments/clock.svg'">
                    </app-svg-icon>
                </div>
                <div class="table-cell-header time-marker" *ngFor="let hour of hoursTimeline">
                    {{hour}}
                 </div>
            </div>
            <div class="events-column">
                <div class="header-row">
                    <div class="table-cell-header-row" *ngFor="let day of week">
                        <div class="day">
                            {{day.weekDayNo | date: 'd'}}
                            {{day.weekDayName}}
                        </div>
                    </div>
                </div>

                <div class="header-row-enlarged">
                    <div class="header-column" *ngFor="let day of week">
                        <div class="event-container" *ngFor="let hour of hoursTimeline; let i = index">
                            <div class="event" *ngIf="filterAppointments(day, hour) > 0; else noEvents">
                                <div *ngFor="let appointment of filteredAppointments" 
                                (click)="editAppointment(appointment)">
                                    <div class="event-alerts">

                                    </div>
                                    <div class="event-data">
                                        <div class="event-patient">
                                            {{appointment.patient?.lastName}} {{appointment.patient?.firstName}}
                                        </div>
                                        <div class="event-description">{{appointment.observations}}</div>
                                    </div>
                                </div>
                            </div>
                            <ng-template #noEvents>
                                <div class="empty-event" (click)="addAppointment(day, hour)">
                                    <div *ngFor="let i of cells" >
                                        
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <!-- <div class="header-row" *ngFor="let hour of hoursTimeline">
                    <div *ngFor="let day of week">
                        <div class="event" *ngIf="filterAppointments(day, hour) > 0; else noEvents">
                            <div class="event-container" *ngFor="let appointment of filteredAppointments" 
                            (click)="editAppointment(appointment)">
                                <div class="event-alerts">

                                </div>
                                <div class="event-data">
                                    <div class="event-patient">
                                        {{appointment.patient?.lastName}} {{appointment.patient?.firstName}}
                                    </div>
                                    <div class="event-description">{{appointment.observations}}</div>
                                </div>
                            </div>
                        </div>
                        <ng-template #noEvents>
                            <div class="empty-event" (click)="addAppointment(day, hour)">
                                <div *ngFor="let i of cells" >
                                    
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div> -->

            </div>
        </div>
    </div>
    <app-shortcuts-container class="container right-container">
    </app-shortcuts-container>
</div>