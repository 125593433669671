import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.development';
import { AuthService } from './auth.service';
import { UtilsService } from './utils.service';
import { EMPTY, Observable, catchError, map } from 'rxjs';
import { PatientNote } from '../user/model/patientNote';

@Injectable({
    providedIn: 'root'
})

export class PatientNotesService {

    headers: HttpHeaders = new HttpHeaders({"Content-Type":"application/json"});

    constructor(private http: HttpClient, private authService: AuthService,
        private utilsService: UtilsService
    ) {}

    getNotes(): Observable<PatientNote[]> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.get<PatientNote[]>(environment.patientNotesUrl, {headers: this.headers})
            .pipe(
                map(notes => notes.map(
                    note => (
                        {
                            id: note.id,
                            user: note.user,
                            note: note.note,
                            patient: note.patient,
                            date: note.date
                        }
                    )
                )),
                catchError((err, caught) => {
                    if (err.status === 403 || err.status === 401) {
                        this.utilsService.sessionExpired();
                    }
                    console.log(err.status);
                    return EMPTY;
                })
            );
    }

    updateNote(note: PatientNote): Observable<PatientNote> {
        
        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.put<PatientNote>(
            environment.patientNotesUrl + "/" + (note.id ? note.id : ''),
            note, {headers: this.headers});
    }

    addNote(note: PatientNote): Observable<PatientNote> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.post<PatientNote>(environment.patientNotesUrl, note, {headers: this.headers});
    }

    deleteNote(id: number): Observable<number> {

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.delete<number>(
            environment.patientNotesUrl + "/" + (id ? id : 0), {headers: this.headers})
    }

    getNotesForPatient(id: string | null): Observable<PatientNote[]> {
        let pathParam;
        if (id !== null) {
            pathParam = Number(id);
        }

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.get<PatientNote[]>(environment.patientNotesUrl + '/patient/' + pathParam, {headers: this.headers})
            .pipe(
                map(notes => notes.map(
                    note => (
                        {
                            id: note.id,
                            user: note.user,
                            note: note.note,
                            patient: note.patient,
                            date: note.date
                        }
                    )
                )),
                catchError((err, caught) => {
                    if (err.status === 403 || err.status === 401) {
                        this.utilsService.sessionExpired();
                    }
                    console.log(err.status);
                    return EMPTY;
                })
            );
    }
}