import { Component, Input, OnInit, Inject } from '@angular/core';
import { DOCUMENT, DatePipe } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { InputItem } from '../../_interfaces/InputItem';
import { Patient } from '../model/patient';
import { Medic } from '../model/medic';
import { Clinic } from '../model/clinic';
import { PatientService } from '../../services/patient.service';
import { MedicService } from '../../services/medic.service';
import { ClinicService } from '../../services/clinic.service';

@Component({
  selector: 'app-patient-modal',
  templateUrl: './patient-modal.component.html',
  styleUrls: ['./patient-modal.component.scss']
})
export class PatientModalComponent implements OnInit{

  @Input() data: {
    patientToUpdate: Patient,
    isEdit: boolean
  }

  // title
  formTitle: string = "ADAUGA PACIENT";
  formTitleIcon: String = "addthis";

  genders: String[] = ["M", "F"];
  medics: Medic[] = [];
  clinics: Clinic[] = [];

  contactItems: InputItem[] = [
    {
      label: 'Nume*',
      inputFields: [
        {
          placeholder: "Nume de familie",
          name: "lastName"
        },
        {
          placeholder: "Prenume",
          name: "firstName"
        }
      ]
    },
    {
      label: 'CNP',
      inputFields: [
        {
          placeholder: "",
          name: "cnp"
        }
      ]
    },
    {
      label: 'Data nasterii',
      inputFields: [
        {
          placeholder: "_ _/_ _/_ _ _ _",
          name: "birthdate",
          isDatePicker: true
        }
      ]
    },
    {
      label: 'Gen',
      inputFields: [
        {
          placeholder: "",
          name: "gender",
          isSelect: true
        }
      ]
    },
    {
      label: 'Telefon',
      inputFields: [
        {
          placeholder: "",
          name: "phone",
        }
      ]
    },
    {
      label: 'Email',
      inputFields: [
        {
          placeholder: "",
          name: "email",
        }
      ]
    },
    {
      label: 'Adresa',
      inputFields: [
        {
          placeholder: "Tara",
          name: "country"
        },
        {
          placeholder: "Judet",
          name: "county"
        },
        {
          placeholder: "Localitate",
          name: "city"
        },
        {
          placeholder: "Strada",
          name: "street"
        }
      ]
    }
  ];

  extraItems: InputItem[] = [
    {
      label: 'Apartinator',
      inputFields: [
        {
          placeholder: "Nume de familie",
          name: "ownerLastName"
        },
        {
          placeholder: "Prenume",
          name: "ownerFirstName"
        },
        {
          placeholder: "Telefon",
          name: "ownerPhone"
        }
      ]
    },
    {
      label: 'Familie',
      inputFields: [
        {
          placeholder: "Familie",
          name: "family"
        },
        {
          placeholder: "Adauga un memnru al familiei",
          name: "familyMember"
        }
      ]
    },
    {
      label: 'Limba pref.',
      inputFields: [
        {
          placeholder: "Limba pref.",
          name: "language"
        }
      ]
    },
    {
      label: 'Contact sec.',
      inputFields: [
        {
          placeholder: "",
          name: "secContact"
        }
      ]
    },
    {
      label: 'Notificari',
      inputFields: [
        {
          placeholder: "SMS",
          name: "smsNotif",
          isCheckBox: true
        },
        {
          placeholder: "Email",
          name: "emailNotif",
          isCheckBox: true
        }
      ]
    },
    {
      label: 'Marketing',
      inputFields: [
        {
          placeholder: "SMS",
          name: "smsMarket",
          isCheckBox: true
        },
        {
          placeholder: "Email",
          name: "emailMarket",
          isCheckBox: true
        },
        {
          placeholder: "Telefon",
          name: "phoneMarket",
          isCheckBox: true
        }
      ]
    }
  ];

  clinicItems: InputItem[] = [
    {
      label: 'ID',
      inputFields: [
        {
          placeholder: "-",
          name: "clinicID"
        }
      ]
    },
    {
      label: 'Medic',
      inputFields: [
        {
          placeholder: "-",
          name: "doctor"
        }
      ]
    },
    {
      label: 'Clinica',
      inputFields: [
        {
          placeholder: "-",
          name: "clinic"
        }
      ]
    }
  ];

  portalItems: InputItem[] = [
    {
      label: 'App',
      inputFields: [
        {
          placeholder: "Enable",
          name: "isEnabled",
          isCheckBox: true
        }
      ]
    },
    {
      label: 'ID link',
      inputFields: [
        {
          placeholder: "-",
          name: "portalLink"
        }
      ]
    }
  ]

  // @Input() operation: string = "";
  constructor(
    @Inject(DOCUMENT) document: Document,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<PatientModalComponent>,
    private patientService: PatientService,
    private medicService: MedicService,
    private clinicService: ClinicService) {
      this.data = {
        patientToUpdate: {
          lastName: "-",
          firstName: "-",
          cnp: "-",
          phone: "-",
          email: "-",
          birthdate: null,
          gender: "-",
          country: "-",
          county: "-",
          city: "-",
          street: "-",
          ownerLastName: "-",
          ownerFirstName: "-",
          ownerPhone: "-",
          familyName: "-",
          familyMember: "-",
          language: "-",
          secondaryContact: "-",
          smsNotifications: false,
          emailNotifications: false,
          smsMarketing: false,
          emailMarketing: false,
          phoneMarketing: false,
          clinic: null,
          doctor: null
        },
        isEdit: false
      }
  }

  ngOnInit(): void {
    if (this.data.isEdit) {
      document.getElementById('form-title')?.classList.add('form-title-edit');
      this.formTitle = "EDITEAZA PACIENT";
      this.formTitleIcon = "pencil";
    }

    this.contactItems = [
      {
        label: 'Nume*',
        inputFields: [
          {
            placeholder: "Nume de familie",
            name: "lastName",
            mapper: this.data.patientToUpdate.lastName
          },
          {
            placeholder: "Prenume",
            name: "firstName",
            mapper: this.data.patientToUpdate.firstName
          }
        ]
      },
      {
        label: 'CNP',
        inputFields: [
          {
            placeholder: "",
            name: "cnp",
            mapper: this.data.patientToUpdate.cnp
          }
        ]
      },
      {
        label: 'Data nasterii',
        inputFields: [
          {
            placeholder: "_ _/_ _/_ _ _ _",
            name: "birthdate",
            isDatePicker: true,
            mapper: this.data.patientToUpdate.birthdate
          }
        ]
      },
      {
        label: 'Gen',
        inputFields: [
          {
            placeholder: "",
            name: "gender",
            isSelect: true,
            mapper: this.data.patientToUpdate.gender
          }
        ]
      },
      {
        label: 'Telefon',
        inputFields: [
          {
            placeholder: "",
            name: "phone",
            mapper: this.data.patientToUpdate.phone
          }
        ]
      },
      {
        label: 'Email',
        inputFields: [
          {
            placeholder: "",
            name: "email",
            mapper: this.data.patientToUpdate.email
          }
        ]
      },
      {
        label: 'Adresa',
        inputFields: [
          {
            placeholder: "Tara",
            name: "country",
            mapper: this.data.patientToUpdate.country
          },
          {
            placeholder: "Judet",
            name: "county",
            mapper: this.data.patientToUpdate.county
          },
          {
            placeholder: "Localitate",
            name: "city",
            mapper: this.data.patientToUpdate.city
          },
          {
            placeholder: "Strada",
            name: "street",
            mapper: this.data.patientToUpdate.street
          }
        ]
      }
    ];
  
    this.extraItems = [
      {
        label: 'Apartinator',
        inputFields: [
          {
            placeholder: "Nume de familie",
            name: "ownerLastName",
            mapper: this.data.patientToUpdate.ownerLastName
          },
          {
            placeholder: "Prenume",
            name: "ownerFirstName",
            mapper: this.data.patientToUpdate.ownerFirstName
          },
          {
            placeholder: "Telefon",
            name: "ownerPhone",
            mapper: this.data.patientToUpdate.ownerPhone
          }
        ]
      },
      {
        label: 'Familie',
        inputFields: [
          {
            placeholder: "Familie",
            name: "familyName",
            mapper: this.data.patientToUpdate.familyName
          },
          {
            placeholder: "Adauga un memnru al familiei",
            name: "familyMember",
            mapper: this.data.patientToUpdate.familyMember
          }
        ]
      },
      {
        label: 'Limba pref.',
        inputFields: [
          {
            placeholder: "Limba pref.",
            name: "language",
            mapper: this.data.patientToUpdate.language
          }
        ]
      },
      {
        label: 'Contact sec.',
        inputFields: [
          {
            placeholder: "",
            name: "secondaryContact",
            mapper: this.data.patientToUpdate.secondaryContact
          }
        ]
      },
      {
        label: 'Notificari',
        inputFields: [
          {
            placeholder: "SMS",
            name: "smsNotif",
            isCheckBox: true,
            mapper: this.data.patientToUpdate.smsNotifications
          },
          {
            placeholder: "Email",
            name: "emailNotif",
            isCheckBox: true,
            mapper: this.data.patientToUpdate.emailNotifications
          }
        ]
      },
      {
        label: 'Marketing',
        inputFields: [
          {
            placeholder: "SMS",
            name: "smsMarket",
            isCheckBox: true,
            mapper: this.data.patientToUpdate.smsMarketing
          },
          {
            placeholder: "Email",
            name: "emailMarket",
            isCheckBox: true,
            mapper: this.data.patientToUpdate.emailMarketing
          },
          {
            placeholder: "Telefon",
            name: "phoneMarket",
            isCheckBox: true,
            mapper: this.data.patientToUpdate.phoneMarketing
          }
        ]
      }
    ];
  
    this.clinicItems = [
      // {
      //   label: 'ID',
      //   inputFields: [
      //     {
      //       placeholder: "-",
      //       name: "clinicID",
      //       mapper: this.data.patientToUpdate.clinic?.id
      //     }
      //   ]
      // },
      {
        label: 'Medic',
        inputFields: [
          {
            placeholder: "-",
            name: "doctor",
            isSelect: true,
            mapper: this.data.patientToUpdate.doctor
          }
        ]
      },
      {
        label: 'Clinica',
        inputFields: [
          {
            placeholder: "-",
            name: "clinic",
            isSelect: true,
            mapper: this.data.patientToUpdate.clinic
          }
        ]
      }
    ];
  
    this.portalItems = [
      {
        label: 'App',
        inputFields: [
          {
            placeholder: "Enable",
            name: "isEnabled",
            isCheckBox: true
          }
        ]
      },
      {
        label: 'ID link',
        inputFields: [
          {
            placeholder: "-",
            name: "portalLink"
          }
        ]
      }
    ]
    this.getMedics();
    this.getClinics();
  }

  getMedics() {
    this.medicService.getMedics().subscribe(medics => {
      this.medics = medics;
    })
  }

  getClinics() {
    this.clinicService.getClinics().subscribe(clinics => {
      this.clinics = clinics;
    })
  }

  addPatient(): void {
    if (this.setPatientValues()) {
      this.patientService.addPatient(this.data.patientToUpdate).subscribe(response => {
        // this.toastr.success('Medic added successfully');
      }, error => {
        // this.toastr.error('Failed to add medic');
      })
      this.dialogRef.close();
    }
  }

  updatePatient(): void {
    if (this.setPatientValues()) {
      this.patientService.updatePatient(this.data.patientToUpdate).subscribe(response => {
        // this.toastr.success('Medic updated successfully');
      }, error => {
        // this.toastr.error('Failed to update medic');
      })
      this.dialogRef.close();
    }
  }

  setPatientValues(): boolean {
    //Contact info
    this.data.patientToUpdate.lastName = this.contactItems[0].inputFields[0].mapper;
    this.data.patientToUpdate.firstName = this.contactItems[0].inputFields[1].mapper;
    this.data.patientToUpdate.cnp = this.contactItems[1].inputFields[0].mapper;
    this.data.patientToUpdate.birthdate = this.contactItems[2].inputFields[0].mapper;
    // this.data.patientToUpdate.gender = this.contactItems[3].inputFields[0].mapper;

    this.data.patientToUpdate.phone = this.contactItems[4].inputFields[0].mapper;
    this.data.patientToUpdate.email = this.contactItems[5].inputFields[0].mapper;
    this.data.patientToUpdate.country = this.contactItems[6].inputFields[0].mapper;
    this.data.patientToUpdate.county = this.contactItems[6].inputFields[1].mapper;
    this.data.patientToUpdate.city = this.contactItems[6].inputFields[2].mapper;
    this.data.patientToUpdate.street = this.contactItems[6].inputFields[3].mapper;
    //Extra info
    this.data.patientToUpdate.ownerLastName = this.extraItems[0].inputFields[0].mapper;
    this.data.patientToUpdate.ownerFirstName = this.extraItems[0].inputFields[1].mapper;
    this.data.patientToUpdate.ownerPhone = this.extraItems[0].inputFields[2].mapper;
    this.data.patientToUpdate.familyName = this.extraItems[1].inputFields[0].mapper;
    this.data.patientToUpdate.familyMember = this.extraItems[1].inputFields[1].mapper;
    this.data.patientToUpdate.language = this.extraItems[2].inputFields[0].mapper;
    this.data.patientToUpdate.secondaryContact = this.extraItems[3].inputFields[0].mapper;
    this.data.patientToUpdate.smsNotifications = this.extraItems[4].inputFields[0].mapper;
    this.data.patientToUpdate.emailNotifications = this.extraItems[4].inputFields[1].mapper;
    this.data.patientToUpdate.smsMarketing = this.extraItems[5].inputFields[0].mapper;
    this.data.patientToUpdate.emailMarketing = this.extraItems[5].inputFields[1].mapper;
    this.data.patientToUpdate.phoneMarketing = this.extraItems[5].inputFields[2].mapper;
    //Clinic info
    // this.data.patientToUpdate.clinic = this.clinicItems[1].inputFields[0].mapper;

    // VALIDATE INPUT FIELDS
    if (this.data.patientToUpdate.lastName === '-' || 
      this.data.patientToUpdate.lastName === '' ||
      this.data.patientToUpdate.lastName === undefined) {
        document.getElementById('lastName')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('lastName')?.classList.remove('invalid-field');
    if (this.data.patientToUpdate.firstName === '-' || 
      this.data.patientToUpdate.firstName === '' ||
      this.data.patientToUpdate.firstName === undefined) {
        document.getElementById('firstName')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('firstName')?.classList.remove('invalid-field');
    if (this.data.patientToUpdate.cnp === '-' || 
      this.data.patientToUpdate.cnp === '' ||
      this.data.patientToUpdate.cnp === undefined) {
        document.getElementById('cnp')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('cnp')?.classList.remove('invalid-field');
    if (this.data.patientToUpdate.birthdate === null || 
      this.data.patientToUpdate.birthdate === undefined) {
        document.getElementById('birthdate')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('birthdate')?.classList.remove('invalid-field');
      this.data.patientToUpdate.birthdate = this.datePipe.transform(this.data.patientToUpdate.birthdate, 'yyyy-MM-dd HH:mm:ss');
    if (this.data.patientToUpdate.gender === '-' || 
      this.data.patientToUpdate.gender === '' ||
      this.data.patientToUpdate.gender === undefined) {
        document.getElementById('gender')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('gender')?.classList.remove('invalid-field');
    if (this.data.patientToUpdate.phone === '-' || 
      this.data.patientToUpdate.phone === '' ||
      this.data.patientToUpdate.phone === undefined) {
        document.getElementById('phone')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('phone')?.classList.remove('invalid-field');
    if (this.data.patientToUpdate.doctor === null || 
      this.data.patientToUpdate.doctor === undefined) {
        document.getElementById('doctor')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('doctor')?.classList.remove('invalid-field');

    return true;
  }

  save(): void {
    if (this.data.isEdit) {
      this.updatePatient();
    } else {
      this.addPatient();
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
