<div class="loginContainer">
	<div class="left_content">
		<div class="titleContainer">
			<h1>the doctor’s best friend</h1>
			<h2>Your Partner in Medical Success</h2>
		</div>
		<div class="image_container_1"></div>
	</div>
	<div class="right_content">
		<div class="image_container_2">
			<p>MedicCopilot</p>
		</div>
		<div class="login_form">
			<form (ngSubmit)="userLogin()">
				<div class="credential_header">
					Email
				</div>
				<input class="credential_input" id="email" type="text" name="email" 
					[(ngModel)]="user.email" placeholder="example@gmail.com">

				<div class="credential_header">
					Password
				</div>
				<input class="credential_input" id="password" type="password" name="password" 
					[(ngModel)]="user.password" placeholder="password">
				<!--TODO: 
					- add show password button

				<span class="btn-show-pass">
					<i class="zmdi zmdi-eye"></i>
				</span>
				-->

				<div class="user_help">
					<div class="remember_me">
						<input class="remember_checkbox" type="checkbox">
						Remember Me
					</div>
					<a class="forgot_pass" href="#">
						Forgot Password?
					</a>
				</div>

				
				<button type="submit" class="LoginBtn">
					Login
				</button>

				<!--TODO: 
					- Add login methods: Google/Facebook
				-->

				<div class="text-center p-t-115">
					<span class="remember_me">
						Don’t have an account ?
					</span>

					<a class="remember_me" href="#">
						Sign Up
					</a>
				</div>
			</form>
		</div>
	</div>
</div>