import { Component, Input, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DOCUMENT, DatePipe } from '@angular/common';
import { SpeechRecognitionService } from '../services/speech-recognition.service';
import { PatientShortModalComponent } from '../patient-short-modal/patient-short-modal.component';
import { Patient } from '../model/patient';
import { Medic } from '../model/medic';
import { Clinic } from '../model/clinic';
import { Appointment } from '../model/appointment';
import { PatientService } from '../../services/patient.service';
import { MedicService } from '../../services/medic.service';
import { AppointmentService } from '../../services/appointment.service';
import { ClinicService } from '../../services/clinic.service';

@Component({
  selector: 'app-appointment-modal',
  templateUrl: './appointment-modal.component.html',
  styleUrls: ['./appointment-modal.component.scss']
})

export class AppointmentModalComponent implements OnInit{

  @Input() data: {
    appointmentToUpdate: Appointment,
    isEdit: boolean
  }

  formTitle: String = "ADAUGA PROGRAMARE";
  formTitleIcon: String = "addthis";

  currentApp: Appointment;
  holdingBtn: boolean = false;

  medics: Medic[] = [];
  clinics: Clinic[] = [];
  patients: Patient[] = [];
  durationOptions: String[] = ["15 min", "30 min", "60 min", "90 min", "120 min"];
  hours: String[] = [];

  constructor(
    @Inject(DOCUMENT) document: Document,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<AppointmentModalComponent>,
    private speechRecognitionService: SpeechRecognitionService,
    public dialog: MatDialog,
    private patientService: PatientService,
    private medicService: MedicService,
    private appointmentService: AppointmentService,
    private clinicService: ClinicService) {
      this.data = {
        appointmentToUpdate: {
          doctor: null,
          clinic: null,
          patient: null,
          date: null,
          hour: "-",
          duration: "60 min",
          information: "",
          blocked: false,
          observations: "",
          confirmed: false,
          arrived: false,
          finalized: false
        }, 
        isEdit: false
      }
      this.currentApp = this.data.appointmentToUpdate;
      this.hours = this.generateHours();
  }

  ngOnInit(): void {
    this.getMedics();
    this.getPatients();
    this.getClinics();
    this.currentApp = this.data.appointmentToUpdate;
    if (this.data.isEdit) {
      document.getElementById('form-title')?.classList.add('form-title-edit');
      this.formTitle = "EDITEAZA PROGRAMAREA";
      this.formTitleIcon = "pencil";
    }
  }

  async startSpeechRecognition() {
    try {
      this.currentApp.observations = this.currentApp.observations + await this.speechRecognitionService.startRecognition();
    } catch (error) {
      console.error('Speech recognition error:', error);
    }
  }

  onSpeechRecBtn() {
    this.holdingBtn = true;
  }

  stopSpeechRecognition() {
    this.speechRecognitionService.stopRecognition();
    this.holdingBtn = false;
  }

  generateHours(): string[] {
    let hoursArray: string[] = [];
    for(let hour = 0; hour < 21; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        hoursArray.push(formattedHour);
      }
    }
    return hoursArray;
  }

  addPatient(): void {
    const dialogRef = this.dialog.open(PatientShortModalComponent);
    dialogRef.afterClosed().subscribe(result => {
      this.getPatients();
    });
  }

  getMedics(): void {
    this.medicService.getMedics().subscribe(medics => {
      this.medics = medics;
    })
  }

  getPatients(): void {
    this.patientService.getPatients().subscribe(patients => {
      this.patients = patients;
    })
  }

  getClinics(): void {
    this.clinicService.getClinics().subscribe(clinics => {
      this.clinics = clinics;
    })
  }

  // CRUD operations
  addAppointment(): void {
    if (this.isValidAppointment()) {
      this.appointmentService.addAppointment(this.currentApp).subscribe(response => {
        // this.toastr.success('Medic added successfully');
      }, error => {
        // this.toastr.error('Failed to add medic');
      })
      this.dialogRef.close();
    }
  }

  updateAppointment(): void {
    if (this.isValidAppointment()) {
      this.appointmentService.updateAppointment(this.currentApp).subscribe(response => {
        // this.toastr.success('Medic updated successfully');
      }, error => {
        // this.toastr.error('Failed to update medic');
      })
      this.dialogRef.close();
    }
  }

  // Text Areas mapping values functions
  onInformationInput(event: Event) {
    this.currentApp.information = (event.target as HTMLTextAreaElement).value;
  }

  onObservationsInput(event: Event) {
    this.currentApp.observations = (event.target as HTMLTextAreaElement).value;
  }

  isValidAppointment(): boolean {
    // VALIDATE INPUT FIELDS
    if (this.currentApp.doctor === null || 
      this.currentApp.doctor === undefined) {
        document.getElementById('doctor')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('doctor')?.classList.remove('invalid-field');
    if (this.currentApp.clinic === null || 
      this.currentApp.clinic === undefined) {
        document.getElementById('clinic')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('clinic')?.classList.remove('invalid-field');
    if (this.currentApp.patient === null ||
      this.currentApp.patient === undefined) {
        document.getElementById('patient')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('patient')?.classList.remove('invalid-field');
    if (this.currentApp.date === null ||
      this.currentApp.date === undefined) {
        document.getElementById('date')?.classList.add('invalid-field');
        return false;
    } else {
        document.getElementById('date')?.classList.remove('invalid-field');
        this.currentApp.date = this.datePipe.transform(this.currentApp.date, 'yyyy-MM-dd HH:mm:ss');
    }
    if (this.currentApp.hour === '' ||
      this.currentApp.hour === '-' || 
      this.currentApp.hour === undefined) {
        document.getElementById('hour')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('hour')?.classList.remove('invalid-field');
    if (this.currentApp.observations === '' ||
      this.currentApp.observations === '-' || 
      this.currentApp.observations === undefined) {
        document.getElementById('observations')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('observations')?.classList.remove('invalid-field');
    if (this.currentApp.duration === '' ||
      this.currentApp.duration === '-' || 
      this.currentApp.duration === undefined) {
        document.getElementById('duration')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('duration')?.classList.remove('invalid-field');
    return true;
  }

  changePatientDetails():void {
    this.currentApp.doctor = this.currentApp.patient?.doctor;
    this.currentApp.clinic = this.currentApp.patient?.clinic;
  }

  save(): void {
    if (this.data.isEdit) {
      this.updateAppointment();
    } else {
      this.addAppointment();
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
