<div class="modal-container">
    <div class="form-title" id="form-title">
        <app-svg-icon class="svg-size-title" [path]='"../../assets/images/appointments/" + formTitleIcon + ".svg"'></app-svg-icon>
        <div class="title">{{formTitle}}</div>
    </div>
    <div class="contact-container">
        <div class="info-row" *ngFor="let item of inputItems">
            <div class="sub-header field">{{item.label}}</div>
            <div class="input-row">
                <div class="sub-header">:</div>
                <input class="info-input" type="text" name={{inputItem.name}} 
                    placeholder={{inputItem.placeholder}} 
                    *ngFor="let inputItem of item.inputFields"
                    [(ngModel)]="inputItem.mapper"
                    [id]=inputItem.name.toString()>
            </div>
        </div>
    </div>
    <div class="modal-actions">
        <button class="save-btn" (click)="save()">
            <app-svg-icon class="save-size" [path]="'../../assets/images/btns/save.svg'">
            </app-svg-icon>
            Save
        </button>
        <button class="cancel-btn" (click)="close()">Cancel</button>
    </div>
</div>
