import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class AuthenticationGuardService {

    static canActivate(): CanActivateFn {
      return (route, state) => {
        const router = inject(Router);
        if (localStorage.getItem('auth_token') === null) {
          router.navigate(['/login']);
          return false;
        } else {
          return true;
        }
      }
    }

    static shouldLogin(): CanActivateFn {
      return (route, state) => {
        const router = inject(Router);
        if (localStorage.getItem('auth_token') === null) {
          return true;
        } else {
          router.navigate(['/']);
          return false;
        }
      }
    }
}