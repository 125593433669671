import { Component, Input, OnInit, Inject } from '@angular/core';
import { DOCUMENT, DatePipe } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { InputItem } from '../../_interfaces/InputItem';
import { Medic } from '../model/medic';
import { MedicService } from '../../services/medic.service';
import { ToastrService } from 'ngx-toastr'; 

@Component({
  selector: 'app-medic-modal',
  templateUrl: './medic-modal.component.html',
  styleUrls: ['./medic-modal.component.scss']
})
export class MedicModalComponent implements OnInit{

  @Input() data: {
    medicToUpdate: Medic,
    isEdit: boolean
  }

  // title
  formTitle: string = "ADAUGA MEDIC";
  formTitleIcon: String = "addthis";

  inputItems: InputItem[] = [];
  genders: String[] = ["M", "F"];

  constructor(
    @Inject(DOCUMENT) document: Document,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<MedicModalComponent>,
    private medicService: MedicService,
    private toastr: ToastrService) {
      this.data = {
        medicToUpdate: {
          lastName: "-",
          firstName: "-",
          specialty: "-",
          phone: "-",
          email: "-",
          gender: "-",
          country: "-",
          county: "-",
          city: "-",
          street: "-",
          birthdate: null
        },
        isEdit: false
      }
  }

  ngOnInit(): void {
    if (this.data.isEdit) {
      document.getElementById('form-title')?.classList.add('form-title-edit');
      this.formTitle = "EDITEAZA MEDIC";
      this.formTitleIcon = "pencil";
    }

    this.inputItems = [
      {
        label: 'Nume*',
        inputFields: [
          {
            placeholder: "Nume de familie",
            name: "lastName",
            mapper: this.data.medicToUpdate.lastName
          },
          {
            placeholder: "Prenume",
            name: "firstName",
            mapper: this.data.medicToUpdate.firstName
          }
        ]
      },
      {
        label: 'Specializare',
        inputFields: [
          {
            placeholder: "",
            name: "specialty",
            mapper: this.data.medicToUpdate.specialty
          }
        ]
      },
      {
        label: 'Data nasterii',
        inputFields: [
          {
            placeholder: "_ _/_ _/_ _ _ _",
            name: "birthdate",
            isDatePicker: true,
            mapper: this.data.medicToUpdate.birthdate
          }
        ]
      },
      {
        label: 'Gen',
        inputFields: [
          {
            placeholder: "",
            name: "gender",
            isSelect: true,
            mapper: this.data.medicToUpdate.gender
          }
        ]
      },
      {
        label: 'Telefon',
        inputFields: [
          {
            placeholder: "",
            name: "phone",
            mapper: this.data.medicToUpdate.phone
          }
        ]
      },
      {
        label: 'Email',
        inputFields: [
          {
            placeholder: "",
            name: "email",
            mapper: this.data.medicToUpdate.email
          }
        ]
      },
      {
        label: 'Adresa',
        inputFields: [
          {
            placeholder: "Tara",
            name: "country",
            mapper: this.data.medicToUpdate.country
          },
          {
            placeholder: "Judet",
            name: "county",
            mapper: this.data.medicToUpdate.county
          },
          {
            placeholder: "Localitate",
            name: "city",
            mapper: this.data.medicToUpdate.city
          },
          {
            placeholder: "Strada",
            name: "street",
            mapper: this.data.medicToUpdate.street
          }
        ]
      }
    ];
  }

  addMedic(): void {
    if (this.setMedicValues()) {
      this.medicService.addMedic(this.data.medicToUpdate).subscribe(response => {
        // this.toastr.success('Medic added successfully');
      }, error => {
        // this.toastr.error('Failed to add medic');
      })
      this.dialogRef.close();
    }
  }

  updateMedic(): void {
    if (this.setMedicValues()) {
      this.medicService.updateMedic(this.data.medicToUpdate).subscribe(response => {
        // this.toastr.success('Medic updated successfully');
      }, error => {
        // this.toastr.error('Failed to update medic');
      })
      this.dialogRef.close();
    }
  }

  // TODO: complete with other values for medic
  setMedicValues(): boolean {
    this.data.medicToUpdate.lastName = this.inputItems[0].inputFields[0].mapper;
    this.data.medicToUpdate.firstName = this.inputItems[0].inputFields[1].mapper;
    this.data.medicToUpdate.specialty = this.inputItems[1].inputFields[0].mapper;
    this.data.medicToUpdate.birthdate = this.inputItems[2].inputFields[0].mapper;
    this.data.medicToUpdate.phone = this.inputItems[4].inputFields[0].mapper;
    this.data.medicToUpdate.email = this.inputItems[5].inputFields[0].mapper;
    this.data.medicToUpdate.country = this.inputItems[6].inputFields[0].mapper;
    this.data.medicToUpdate.county = this.inputItems[6].inputFields[1].mapper;
    this.data.medicToUpdate.city = this.inputItems[6].inputFields[2].mapper;
    this.data.medicToUpdate.street = this.inputItems[6].inputFields[3].mapper;

    this.data.medicToUpdate.birthdate = this.datePipe.transform(this.data.medicToUpdate.birthdate, 'yyyy-MM-dd HH:mm:ss');

    // VALIDATE INPUT FIELDS
    if (this.data.medicToUpdate.lastName === '-' || 
      this.data.medicToUpdate.lastName === '' ||
      this.data.medicToUpdate.lastName === undefined) {
        document.getElementById('lastName')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('lastName')?.classList.remove('invalid-field');
    if (this.data.medicToUpdate.firstName === '-' || 
      this.data.medicToUpdate.firstName === '' ||
      this.data.medicToUpdate.firstName === undefined) {
        document.getElementById('firstName')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('firstName')?.classList.remove('invalid-field');
    if (this.data.medicToUpdate.email === '-' || 
      this.data.medicToUpdate.email === '' ||
      this.data.medicToUpdate.email === undefined) {
        document.getElementById('email')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('email')?.classList.remove('invalid-field');
    if (this.data.medicToUpdate.phone === '-' || 
      this.data.medicToUpdate.phone === '' ||
      this.data.medicToUpdate.phone === undefined) {
        document.getElementById('phone')?.classList.add('invalid-field');
        return false;
    } else 
      document.getElementById('phone')?.classList.remove('invalid-field');

    return true;
  }

  save(): void {
    if (this.data.isEdit) {
      this.updateMedic();
    } else {
      this.addMedic();
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}