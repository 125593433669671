import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.development';
import { AuthService } from './auth.service';
import { UtilsService } from './utils.service';
import { EMPTY, Observable, catchError, map } from 'rxjs';
import { PatientTeethMap } from '../user/model/patientTeethMap';

@Injectable({
    providedIn: 'root'
})
export class PatientTeethMapService {

    headers: HttpHeaders = new HttpHeaders({"Content-Type":"application/json"});

    constructor(private http: HttpClient, private authService: AuthService,
        private utilsService: UtilsService
    ) {}

    getTeethMapForPatient(id: string | null): Observable<PatientTeethMap> {
        let pathParam;
        if (id !== null) {
            pathParam = Number(id);
        }

        if (this.authService.getAuthToken() !== null) {
            this.headers = new HttpHeaders({
                "Authorization": "Bearer " + this.authService.getAuthToken(),
                "Content-Type":"application/json"
            })
        }

        return this.http.get<PatientTeethMap>(environment.teethMapUrl + '/patient/' + pathParam, {headers: this.headers})
            .pipe(
                map(teethMap => (
                    {
                        id: teethMap.id,
                        patient: teethMap.patient,
                        tooth18: teethMap.tooth18,
                        tooth17: teethMap.tooth17,
                        tooth16: teethMap.tooth16,
                        tooth15: teethMap.tooth15,
                        tooth14: teethMap.tooth14,
                        tooth13: teethMap.tooth13,
                        tooth12: teethMap.tooth12,
                        tooth11: teethMap.tooth11,
                        tooth21: teethMap.tooth21,
                        tooth22: teethMap.tooth22,
                        tooth23: teethMap.tooth23,
                        tooth24: teethMap.tooth24,
                        tooth25: teethMap.tooth25,
                        tooth26: teethMap.tooth26,
                        tooth27: teethMap.tooth27,
                        tooth28: teethMap.tooth28,
                        tooth38: teethMap.tooth38,
                        tooth37: teethMap.tooth37,
                        tooth36: teethMap.tooth36,
                        tooth35: teethMap.tooth35,
                        tooth34: teethMap.tooth34,
                        tooth33: teethMap.tooth33,
                        tooth32: teethMap.tooth32,
                        tooth31: teethMap.tooth31,
                        tooth41: teethMap.tooth41,
                        tooth42: teethMap.tooth42,
                        tooth43: teethMap.tooth43,
                        tooth44: teethMap.tooth44,
                        tooth45: teethMap.tooth45,
                        tooth46: teethMap.tooth46,
                        tooth47: teethMap.tooth47,
                        tooth48: teethMap.tooth48
                    }
                )),
                catchError((err, caught) => {
                    if (err.status === 403 || err.status === 401) {
                        this.utilsService.sessionExpired();
                    }
                    console.log(err.status);
                    return EMPTY;
                })
            );
    }
}