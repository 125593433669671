import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsDto } from '../_models/credentialsDto';
import { AuthService } from '../services/auth.service';
import { UserDto } from '../_models/userDto';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit{

  user: CredentialsDto = new CredentialsDto();

  constructor(private router: Router,
    private authService: AuthService) {}

  ngOnInit(): void {
    
  }

  userLogin() {
    this.authService.loginUser(this.user).subscribe({
      next: (response) => {
        this.authService.setAuthToken(response.token);
        this.authService.setUserDetails(response);
        this.router.navigate(['/']);
      },
      error: (error) => {
        alert(error);
      }
    })

    /*
    * TODO: verify why the menu is not displayed after login
    */
    // localStorage.setItem('isUserLoggedIn', "true");
    // this.router.navigate(['/']);
  }
}
