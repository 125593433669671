<div class="medics-page">
  <div class="container left-container">
    <div class="patient-details-container">
      <div class="patient-details-section-top main-details">
        <div class="photo-placeholder">
            Photo Placeholder
        </div>
        <div class="column1">
          <div class="header">
            {{currentMedic.lastName}} {{currentMedic.firstName}}
          </div>
          <div class="sub-header">
            <span class="field">DOB </span><span>:{{currentMedic.lastName}}</span>
          </div>
          <div class="sub-header">
            <span class="field">ID </span><span>:{{currentMedic.id}}</span>
          </div>
          <div class="sub-header">
            <span class="field">Gen </span>
            <span>: {{currentMedic.gender}}</span>
          </div>
          <div class="sub-header">
            <span class="field">Specializare </span><span>:{{currentMedic.specialty}}</span>
          </div>
        </div>
        <div class="column1">
          <div class="header2">
            Contact Info
          </div>
          <div class="sub-header">
            <span class="field">Telefon </span><span>:{{currentMedic.phone}}</span>
          </div>
          <div class="sub-header">
            <span class="field">Email </span><span>:{{currentMedic.email}}</span>
          </div>
        </div>
        <div class="column1 column-end-right">
          <small class="d-block"></small>
          <button class="edit-btn" (click)="editMedic(this.currentMedic)">
            <app-svg-icon class="svg-size" [path]="'../../assets/images/btns/pencil.svg'">
            </app-svg-icon>
            <div>Edit</div>
          </button>
        </div>
      </div>
      <div class=" patient-details-section secondary-details">

      </div>
      <div class="patient-details-section payments-details">

      </div>
    </div>
    
    <button class="add-patient-btn" (click)="addMedic()">
      <div class="left-btn">
        +
      </div>
      <div class="right-btn">
        Medic
      </div>
    </button>
    <div class="patient-list-container">
      <table class="table-responsive patients-table">
        <thead>
          <tr>
            <th scope="col">ID
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Nume
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Prenume
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Specializare
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Telefon
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col">Email
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="keywords">
              </div>
            </th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let medic of medics" (click)="selectRow(medic)">
            <td>{{medic.id}}</td>
            <td>{{medic.lastName}}</td>
            <td>{{medic.firstName}}</td>
            <td>{{medic.specialty}}</td>
            <td>{{medic.phone}}</td>
            <td>{{medic.email}}</td>
            <td>
              <button class="edit-btn" (click)="editMedic(medic)">
                <app-svg-icon class="svg-size" [path]="'../../assets/images/btns/pencil.svg'">
                </app-svg-icon>
                <div>Edit</div>
              </button>
            </td>
            <td>
              <button class="delete-btn" (click)="deleteMedic(medic)">
                <app-svg-icon class="svg-size" [path]="'../../assets/images/btns/trash.svg'">
                </app-svg-icon>
              <div>Delete</div>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <app-shortcuts-container class="container right-container">
  </app-shortcuts-container>
</div>