import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthenticationGuardService } from './authentication.guard';

const userModule = () => import('./user/user.module').then(x => x.UserModule);

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthenticationGuardService.shouldLogin()]
  },
  {
    path: '',
    loadChildren: userModule,
    canActivate: [AuthenticationGuardService.canActivate()]
  },
  {
    path: 'user',
    loadChildren: userModule,
    canActivate: [AuthenticationGuardService.canActivate()]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}