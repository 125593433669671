import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Medic } from '../model/medic';
import { MatDialog } from '@angular/material/dialog';
import { MedicModalComponent } from '../medic-modal/medic-modal.component';
import { MedicService } from '../../services/medic.service';
import { ToastrService } from 'ngx-toastr'; 

@Component({
  selector: 'app-medic',
  templateUrl: './medic.component.html',
  styleUrls: ['./medic.component.scss']
})
export class MedicComponent implements OnInit, OnChanges{

  currentMedic: Medic = { 
    lastName: "-",
    firstName: "-",
    specialty: "-",
    phone: "-",
    email: "-",
    gender: "-",
    country: "-",
    county: "-",
    city: "-",
    street: "-",
    birthdate: null
  };

  medics: Medic[] = [];

  constructor(public dialog: MatDialog, private medicService: MedicService,
    private toastr: ToastrService) {}

  ngOnInit(): void {
    this.getMedics();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getMedics();
  }

  selectRow(medic:Medic): void {
    this.currentMedic = medic;
  }

  getMedics(): void {
    this.medicService.getMedics().subscribe(medics => {
      this.medics = medics;
    })
  }

  editMedic(medic: Medic): void {
    const dialogRef = this.dialog.open(MedicModalComponent);
    dialogRef.componentInstance.data = {
      medicToUpdate: medic,
      isEdit: true
    }

    dialogRef.afterClosed().subscribe(result => {
      this.getMedics();
    });
  }

  addMedic(): void {
    const dialogRef = this.dialog.open(MedicModalComponent);
    dialogRef.componentInstance.data = {
      medicToUpdate: { 
        lastName: "-",
        firstName: "-",
        specialty: "-",
        phone: "-",
        email: "-",
        gender: "-",
        country: "-",
        county: "-",
        city: "-",
        street: "-",
        birthdate: null
      },
      isEdit: false
    }

    dialogRef.afterClosed().subscribe(result => {
      this.getMedics();
    });
  }

  deleteMedic(medic: Medic): void {
    this.medicService.deleteMedic(medic.id ? medic.id : 0).subscribe(medic => {
      this.getMedics();
    });
  }
}
