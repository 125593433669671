<div class="treatments-page">
    <div class="container left-container">
        <div class="treatments-filter-container">
            <div class="filter-tag">
                <app-svg-icon class="svg-size" [path]="'../../assets/images/appointments/filter.svg'">
                </app-svg-icon>
                <div> Filtre:</div>
            </div>
            <div class="filter-options">
                <p>Tratament</p>
                <input type="text">
                <p>Categorie</p>
                <select class="select" id="treatmentCategory" 
                [(ngModel)]="selectedCategory" name="selectedCategory">
                    <option value=null disabled selected>Categorii tratamente</option>
                    <option *ngFor="let category of treatmentCategories" [ngValue]="category">
                        {{category.categoryName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="action-btns">
          <button class="add-treatment-btn" (click)="addTreatment()">
              <div class="left-btn">
                +
              </div>
              <div class="right-btn">
                Adauga tratament
              </div>
          </button>
          <button class="cloud-btn" (click)="addTreatment()">
              <app-svg-icon class="svg-size" [path]="'../../assets/images/btns/cloud.svg'">
              </app-svg-icon>
              <div class="right-btn">
                Importa tratament
              </div>
          </button>
        </div>
        <div class="treatments-list-container">
            <table class="table-responsive treatments-table">
              <thead>
                <tr>
                  <th scope="col">ID
                    <div class="input-group">
                      <input type="text" class="form-control" placeholder="" id="keywords">
                    </div>
                  </th>
                  <th scope="col">Denumire
                    <div class="input-group">
                      <input type="text" class="form-control" placeholder="" id="keywords">
                    </div>
                  </th>
                  <th scope="col">Categorie
                    <div class="input-group">
                      <input type="text" class="form-control" placeholder="" id="keywords">
                    </div>
                  </th>
                  <th scope="col">Pret
                    <div class="input-group">
                      <input type="text" class="form-control" placeholder="" id="keywords">
                    </div>
                  </th>
                  <th scope="col">Pret per
                    <div class="input-group">
                      <input type="text" class="form-control" placeholder="" id="keywords">
                    </div>
                  </th>
                  <th scope="col">Pictograma
                    <div class="input-group">
                      <input type="text" class="form-control" placeholder="" id="keywords">
                    </div>
                  </th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let treatment of treatments" (click)="selectRow(treatment)">
                  <td>{{treatment.id}}</td>
                  <td>{{treatment.treatmentName}}</td>
                  <td>{{treatment.treatmentCategory?.categoryName}}</td>
                  <td>{{treatment.price}}</td>
                  <td>{{treatment.pricePer}}</td>
                  <td>{{treatment.iconName}}</td>
                  <td>
                    <button class="edit-btn" (click)="editTreatment(treatment)">
                      <app-svg-icon class="svg-size-table" [path]="'../../assets/images/btns/pencil.svg'">
                      </app-svg-icon>
                      <div>Edit</div>
                    </button>
                  </td>
                  <td>
                    <button class="delete-btn" (click)="deleteTreatment(treatment)">
                      <app-svg-icon class="svg-size-table" [path]="'../../assets/images/btns/trash.svg'">
                      </app-svg-icon>
                    <div>Delete</div>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
    </div>
    <app-shortcuts-container class="container right-container">
    </app-shortcuts-container>
</div>
